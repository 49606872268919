import { Link, Typography } from "@2po/component-library";
import styled from "styled-components";

export const StyledLink = styled(Link)`
    padding: 0;
    display: inline;
    font-size: inherit;
    text-transform: none;
    font-weight: inherit;
`;

export const StyledParagraph = styled(Typography)`
    width: 100%;
`;
