import {
    Background,
    breakpointUp,
    spacer,
    Spacers,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import { DefaultBackgroundProps } from "./Background";

export const StyledBackground = styled(Background)<DefaultBackgroundProps>`
    ${({ size }) => {
        if (!size) {
            return;
        }

        const sizeMapper: any = {
            small: {
                mobile: 300,
                desktop: 400,
            },

            medium: {
                mobile: 400,
                desktop: 800,
            },
        };

        return css`
            padding-top: ${spacer(sizeMapper[size]?.mobile as Spacers)};
            padding-bottom: ${spacer(sizeMapper[size]?.mobile as Spacers)};

            ${breakpointUp(
                "m",
                css`
                    padding-top: ${spacer(
                        sizeMapper[size]?.desktop as Spacers
                    )};
                    padding-bottom: ${spacer(
                        sizeMapper[size]?.desktop as Spacers
                    )};
                `
            )}
        `;
    }}
`;
