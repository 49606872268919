import { css } from "styled-components";

export const containerBreakLeft = css`
    margin-left: calc((100vw - 100%) / -2);
`;

export const containerBreakRight = css`
    margin-right: calc((100vw - 100%) / -2);
`;

export const containerBreak = css`
    width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
    margin-right: calc((100vw - 100%) / -2);
`;
