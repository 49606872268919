import { Background } from "@2po/component-library";
import { containerBreak } from "@utils";
import styled from "styled-components";

export const StyledBackground = styled(Background)`
    ${containerBreak}
`;

export const ChainItemWrapper = styled.div<{ height: number }>`
    height: ${({ height }) => height && `${height}px`};
`;

export const ImageWrapper = styled.div<{ width: number }>`
    filter: grayscale(1);
    width: max-content;
    max-width: ${({ width }) => width && `${width}px`};
`;
