import { Footer, NavLink } from "@2po/component-library";
import { mapNavigationLinks } from "@utils";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export interface NavigationInterface {
    className?: string;
}

const FooterComponent = ({ className }: NavigationInterface) => {
    const { footer } = useStaticQuery(graphql`
        query FooterQuery {
            footer: contentfulFooter {
                links {
                    ...ContentCtaData
                }
            }
        }
    `);

    const links = mapNavigationLinks(footer?.links) as NavLink[];

    if (!links) return null;

    return <Footer className={className} links={links} />;
};

export default FooterComponent;
