import { Colors } from "@2po/component-library";
import React, { MouseEventHandler, ReactNode } from "react";
import {
    ImageWrapper,
    StyledSidebarItem,
    StyledTitle,
} from "./SidebarItem.styled";

export interface SidebarItemProps {
    activeColor?: Colors;
    backgroundColor?: Colors;
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    title: string;
    image: ReactNode;
    active?: boolean;
}

const SidebarItem = ({
    activeColor = "primary",
    backgroundColor = "light",
    className,
    onClick,
    title,
    image,
    active,
}: SidebarItemProps) => (
    <StyledSidebarItem
        activeColor={activeColor}
        backgroundColor={backgroundColor}
        className={className}
        onClick={onClick}
    >
        <ImageWrapper backgroundColor={active ? activeColor : backgroundColor}>
            {image}
        </ImageWrapper>
        <StyledTitle active={active} type="h4">
            {title}
        </StyledTitle>
    </StyledSidebarItem>
);

export default SidebarItem;
