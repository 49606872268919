import { Column, Grid } from "@2po/component-library";
import { ContentfulRichText, DefaultBackground } from "@components";
import { ISectionInfo } from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import { StyledTopic } from "./ContentfulSectionInfo.styled";

const ContentfulSectionInfo = ({
    content,
    className,
}: {
    content: ISectionInfo;
    className?: string;
}) => {
    if (!content) {
        return null;
    }
    const { title, content: blockTextCopy, color } = content;

    return (
        <DefaultBackground className={className} color={color?.name}>
            <Grid>
                <Column lSpan={2} mSpan={1} xlSpan={4} xsSpan={4}>
                    <StyledTopic
                        color={handleTextColorByBackground(color?.name)}
                        type="h4"
                    >
                        {title}
                    </StyledTopic>
                </Column>
                <Column lSpan={6} mSpan={3} xlSpan={8} xsSpan={4}>
                    <ContentfulRichText
                        content={blockTextCopy}
                        textColor={handleTextColorByBackground(color?.name)}
                    />
                </Column>
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionInfo;

export const query = graphql`
    fragment SectionInfoData on ContentfulSectionInfo {
        title
        content {
            ...ContentRichTextData
        }
        color {
            name
        }
    }
`;
