import { Typography } from "@2po/component-library";
import { isProduction } from "@utils";
import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
    children?: ReactNode;
    message: string;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

// NOTE: This component ONLY Catches run time errors
export default class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // eslint-disable-next-line no-console
        console.log(
            "componentDidCatch - components/ErrorBoundary/ErrorBoundary",
            error,
            errorInfo
        );
    }

    render() {
        const { message, children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            if (isProduction()) {
                return null;
            }

            return (
                <Typography type="h3">
                    {message || "Something went wrong."}
                </Typography>
            );
        }

        return children;
    }
}
