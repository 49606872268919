import { breakpointUp, Typography } from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledTopic = styled(Typography)`
    ${breakpointUp(
        "m",
        css`
            text-align: end;
        `
    )}
`;
