import { Asset } from "@types";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { CSSProperties } from "react";

interface ImageInterface {
    media: Asset;
    alt?: string;
    className?: string;
    loading?: "eager" | "lazy";
    style?: CSSProperties;
}

const Image = ({
    media,
    alt,
    loading = "lazy",
    className,
    ...props
}: ImageInterface): JSX.Element | null => {
    const image = getImage(media);

    if (!image) {
        const backupUrl = media?.file?.url;
        if (backupUrl)
            return (
                <img
                    alt={alt || media?.description}
                    className={className}
                    src={backupUrl}
                />
            );

        return null;
    }

    return (
        <GatsbyImage
            alt={alt || media?.description}
            className={className}
            image={image as IGatsbyImageData}
            loading={loading}
            {...props}
        />
    );
};
export default Image;
