import { breakpointValues } from "@2po/component-library";
import { useMemo } from "react";
import { useWindowSize as useWindowSizeState } from "usehooks-ts";

const useWindowSize = () => {
    const { width, ...rest } = useWindowSizeState();

    const screens = useMemo(
        () => ({
            isMobile: width < breakpointValues.m,
        }),
        [width]
    );

    return { width, ...rest, ...screens };
};

export default useWindowSize;
