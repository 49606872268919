import { breakpointValues, CardCase } from "@2po/component-library";
import { ContentfulImage } from "@components";
import { ICase } from "@types";
import { graphql } from "gatsby";
import React from "react";
import { MasonryProps } from "react-masonry-css";
import { StyledMasonry } from "./CasesMasonry.styled";

export type MasonryTypes = "level" | "ascending" | "descending";

interface CasesMasonryInterface {
    cases: ICase[];
    masonryProps?: MasonryProps;
    type?: MasonryTypes;
}

const CasesMasonry = ({
    cases,
    masonryProps,
    type = "descending",
}: CasesMasonryInterface) => {
    if (!cases) {
        return null;
    }

    return (
        <StyledMasonry
            breakpointCols={{
                default: 2,
                [breakpointValues.l]: 1,
            }}
            className=""
            columnClassName="project-masonry-columns"
            type={type}
            {...masonryProps}
        >
            {cases?.map(
                (
                    { id, caseSpecs, background, client, title, image, page },
                    index
                ) => (
                    <CardCase
                        background={() =>
                            background && (
                                <ContentfulImage
                                    content={background}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                />
                            )
                        }
                        eyebrow={["Two Point O", client?.name || ""]}
                        image={() =>
                            image && <ContentfulImage content={image} />
                        }
                        key={`${id}-masonry-item-${index}`}
                        linkHref={page?.[0]?.slug && `${page[0].slug}`}
                        linkLabel="see case"
                        tags={caseSpecs?.map(({ displayName }) => displayName)}
                        title={title}
                    />
                )
            )}
        </StyledMasonry>
    );
};

export default CasesMasonry;

export const query = graphql`
    fragment CaseCardData on ContentfulCase {
        page {
            slug
        }
        client {
            name
        }
        title
        caseSpecs {
            ...GeneralModelData
            ... on ContentfulService {
                displayName
            }
            ... on ContentfulSolution {
                displayName
            }
            ... on ContentfulTechnology {
                displayName
            }
        }
        background {
            ...GeneralImageData
            media {
                gatsbyImageData(
                    layout: FIXED
                    width: 975
                    placeholder: DOMINANT_COLOR
                )
            }
        }
        image {
            ...GeneralImageData
            media {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    breakpoints: [360, 544, 640, 1024, 1536]
                )
            }
        }
    }
`;
