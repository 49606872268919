import {
    breakpointUp,
    Header,
    LogoPattern,
    spacer,
    zIndex,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import { ContentfulImage } from "../";
import { DefaultBackground } from "../../Defaults"; // Can't use @component due to import order

export const StyledBackground = styled(DefaultBackground)`
    position: relative;
    padding-top: ${spacer(400)};
`;

export const StyledLogoPattern = styled(LogoPattern)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: ${zIndex("base")};
    width: 100vw;
    max-width: 1700px;
`;

export const StyledContentfulImage = styled(ContentfulImage)`
    z-index: ${zIndex("base-overwrite")};

    ${breakpointUp(
        "l",
        css`
            min-height: 500px;
        `
    )}
`;

export const StyledHeader = styled(Header)`
    ${breakpointUp(
        "xl",
        css`
            padding-bottom: ${spacer(200)};
        `
    )}
`;
