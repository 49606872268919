import { IService } from "@types";
import React from "react";
import ContentfulImage from "../ContentfulImage/ContentfulImage";
import { StyledTag } from "./ContentfulService.styled";

interface ContentfulServiceProps {
    content: IService;
    className?: string;
}

const ContentfulService = ({ content, className }: ContentfulServiceProps) => {
    const { thumbnail, displayName } = content;

    return (
        <StyledTag className={className} padding={125}>
            <ContentfulImage
                content={thumbnail}
                hasCta={false}
                style={{ width: "44px" }}
            />
            {displayName}
        </StyledTag>
    );
};

export default ContentfulService;
