import { ContentfulRichText, DefaultBackground } from "@components";
import { ISectionRichText } from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";

interface ContentfulSectionRichTextInterface {
    content?: ISectionRichText;
    className?: string;
}

const ContentfulSectionRichText = ({
    content,
    className,
}: ContentfulSectionRichTextInterface) => {
    if (!content) {
        return null;
    }

    const { color, content: RTEContent } = content;

    return (
        <DefaultBackground className={className} color={color?.name}>
            <ContentfulRichText
                content={RTEContent}
                textColor={handleTextColorByBackground(color?.name)}
            />
        </DefaultBackground>
    );
};

export default ContentfulSectionRichText;

export const query = graphql`
    fragment SectionRichTextData on ContentfulSectionRichText {
        content {
            ...ContentRichTextData
        }
        color {
            name
        }
    }
`;
