import { Colors } from "@2po/component-library";
import React from "react";
import {
    StyledBackground,
    StyledHeader,
    StyledLogoPattern,
} from "./CaseHero.styled";

interface CaseHeroInterface {
    eyebrow: string[];
    title: string;
    backgroundColor?: Colors;
    color?: Colors;
    backgroundImageUrl?: string;
}

const CaseHero = ({
    eyebrow,
    title,
    backgroundImageUrl,
    backgroundColor = "black",
}: CaseHeroInterface) => (
    <StyledBackground color={backgroundColor}>
        <StyledHeader eyebrow={eyebrow} headerType="h1" title={title} />
        <StyledLogoPattern
            background={backgroundImageUrl}
            color="primary"
            repeatX={3}
        />
    </StyledBackground>
);

export default CaseHero;
