import { breakpointOnly, Carousel } from "@2po/component-library";
import styled, { css } from "styled-components";
import { containerBreak } from "@utils";

export const StyledCarousel = styled(Carousel)`
    .contentfulCarousel {
        ${containerBreak}
    }

    img {
        ${breakpointOnly(
            "xs",
            "m",
            css`
                max-height: 300px;
            `
        )}

        ${breakpointOnly(
            "m",
            "l",
            css`
                max-height: 450px;
            `
        )}
    }
`;
