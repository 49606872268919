import { Button, Colors, Link } from "@2po/component-library";
import { PageHeaderColorContext } from "@contexts/PageHeaderColorContext";
import { IContentCta } from "@types";
import { graphql } from "gatsby";
import React, { useContext } from "react";

interface MapperProps {
    buttonBackgroundColor?: Colors;
    className?: string;
    content: IContentCta;
    linkBackgroundColor?: Colors;
    type?: "Link" | "Button";
}

const ContentfulCallToAction = ({
    buttonBackgroundColor = "black",
    className,
    content,
    linkBackgroundColor,
    type = "Button",
}: MapperProps) => {
    if (!content) {
        return null;
    }

    const context = useContext(PageHeaderColorContext);
    const headerColor = context?.pageHeaderColor;
    const linkColor = headerColor ?? "black";
    const linkHoverColor =
        headerColor === "secondary" ? "primary" : "secondary";

    const { externalLink, internalLink, label } = content;

    const link = internalLink
        ? `/${internalLink.slug}` || ""
        : externalLink
        ? externalLink
        : "";

    if (type === "Link") {
        return (
            <Link
                backgroundColor={linkBackgroundColor}
                className={className}
                color={linkColor}
                colorHover={linkHoverColor}
                href={link}
                icon="chevronRight"
            >
                {label}
            </Link>
        );
    }

    return (
        <Button
            backgroundColor={buttonBackgroundColor}
            backgroundColorHover={headerColor}
            className={className}
            color="white"
            href={link}
            icon="chevronRight"
        >
            {label}
        </Button>
    );
};

export default ContentfulCallToAction;

export const query = graphql`
    fragment ContentCtaData on ContentfulContentCta {
        label
        externalLink
        internalLink {
            slug
        }
    }
`;
