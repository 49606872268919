import { CasesMasonry, DefaultBackground } from "@components";
import { ISectionCases } from "@types";
import { graphql } from "gatsby";
import React from "react";
import { ContentWrapper, StyledTitle } from "./ContentfulSectionCases.styled";

interface ContentfulSectionCasesProps {
    content: ISectionCases;
    className?: string;
}

const ContentfulSectionCases = ({
    content,
    className,
}: ContentfulSectionCasesProps) => {
    if (!content?.cases) {
        return null;
    }

    const { color, title, cases } = content;

    return (
        <DefaultBackground className={className} color={color?.name}>
            <ContentWrapper>
                <StyledTitle color="secondary" type="label">
                    {title}
                </StyledTitle>
                <CasesMasonry cases={cases} type="ascending" />
            </ContentWrapper>
        </DefaultBackground>
    );
};

export default ContentfulSectionCases;

export const query = graphql`
    fragment SectionCasesData on ContentfulSectionCases {
        title
        cases {
            ...CaseCardData
        }
        color {
            name
        }
    }
`;
