import { Grid } from "@2po/component-library";
import styled from "styled-components";

export const StyledGrid = styled(Grid)`
    direction: ${({ reverse }) => (reverse ? "rtl" : "ltr")};

    > * {
        direction: ltr;
    }
`;
