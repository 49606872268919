import {
    Background,
    breakpointUp,
    Header,
    LogoPattern,
    spacer,
    zIndex,
} from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledBackground = styled(Background)`
    display: flex;
    flex-direction: column;
    gap: ${spacer(300)};
    padding-top: ${spacer(300)};

    ${breakpointUp(
        "m",
        css`
            gap: ${spacer(400)};
            padding-top: ${spacer(400)};
        `
    )}
`;

export const StyledHeader = styled(Header)`
    ${breakpointUp(
        "l",
        css`
            max-width: 75%;
        `
    )}
`;

export const StyledLogoPattern = styled(LogoPattern)`
    left: 50%;
    transform: translateX(-50%);
    width: 200vw;
    z-index: ${zIndex("base")};
    position: relative;
    overflow: hidden;

    ${breakpointUp(
        "m",
        css`
            width: 150vw;
        `
    )}

    ${breakpointUp(
        "l",
        css`
            width: 100vw;
        `
    )}
`;
