import {
    breakpointDown,
    breakpointUp,
    spacer,
    Typography,
} from "@2po/component-library";
import styled, { css } from "styled-components";

export const ContentWrapper = styled.div`
    position: relative;

    ${breakpointDown(
        "l",
        css`
            display: flex;
            flex-direction: column;
            gap: ${spacer(300)};
        `
    )}
`;

export const StyledTitle = styled(Typography)`
    ${breakpointUp(
        "l",
        css`
            position: absolute;
            left: 0;
            top: 0;
            max-width: 50%;
        `
    )}
`;
