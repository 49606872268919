import { Container } from "@2po/component-library";
import { Footer } from "@components";
import React from "react";
import { LayoutInterface } from "..";
import { StyledLayoutDefault, StyledNavigation } from "./LayoutDefault.styled";

const LayoutDefault = ({ children }: LayoutInterface): JSX.Element => (
    <StyledLayoutDefault>
        <StyledNavigation />
        <Container>{children}</Container>
        <Footer />
    </StyledLayoutDefault>
);

export default LayoutDefault;
