import {
    CardTextual,
    Column,
    Grid,
    Icons,
    Typography,
} from "@2po/component-library";
import { ContentfulRichText, DefaultBackground } from "@components";
import { ISectionTabs } from "@types";
import { graphql } from "gatsby";
import React from "react";

const ContentfulSectionTabs = ({ content }: { content: ISectionTabs }) => {
    if (!content?.items?.length) return null;

    const { items, color } = content;

    return (
        <DefaultBackground color={color?.name}>
            <Grid>
                <Column lSpan={2} xlSpan={3} xsSpan="full">
                    {content?.title && (
                        <Typography fontWeight="bold" type="h4">
                            {content.title}
                        </Typography>
                    )}
                </Column>
                <Column
                    lSpan={6}
                    lStart={3}
                    xlSpan={9}
                    xlStart={4}
                    xsSpan={3}
                    xsStart={2}
                >
                    <Grid>
                        {items.map((item, index) => (
                            <Column
                                key={`${item.id}-section-tab-item-${index}`}
                                lSpan="half"
                                xsSpan="full"
                            >
                                <CardTextual
                                    copy={
                                        <ContentfulRichText
                                            content={item.content}
                                        />
                                    }
                                    icon={item?.icon?.name as Icons}
                                    title={item.title}
                                />
                            </Column>
                        ))}
                    </Grid>
                </Column>
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionTabs;

export const query = graphql`
    fragment SectionTabsData on ContentfulSectionTabs {
        title
        items {
            ...TabData
        }
        color {
            name
        }
    }
    fragment TabData on ContentfulTab {
        ...GeneralModelData
        title
        content {
            ...ContentRichTextData
        }
        icon {
            name
        }
    }
`;
