import { useSiteMetadata } from "@hooks";
import { IContentImage } from "@types";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface PageHeadInterface {
    description?: string;
    title?: string;
    image?: IContentImage;
    pathname?: string;
    children?: ReactNode;
    noIndexNoFollow?: boolean;
    canonicalUrl: string;
}

const PageHead = ({
    noIndexNoFollow,
    description,
    title,
    image,
    pathname,
    children,
    canonicalUrl,
}: PageHeadInterface): JSX.Element => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        siteUrl,
    } = useSiteMetadata();

    const isProduction = process.env.GATSBY_ENV === "production";
    // If not in production, noIndexNoFollow is true by default
    const noIndexNoFollowVar = !isProduction ? true : noIndexNoFollow ?? true;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        noIndexNoFollow: noIndexNoFollowVar,
        canonicalUrl: canonicalUrl,
    };

    return (
        <>
            <Helmet>
                <title key="title">{seo.title}</title>
                <meta content={seo.description} name="description" />
                <meta content={seo.title} property="og:title" />
                <meta content="website" property="og:type" />
                <meta content={seo.url} property="og:url" />
                {image && (
                    <meta content={image.media.file.url} property="og:image" />
                )}
                <meta
                    content={
                        seo.noIndexNoFollow
                            ? "noindex,nofollow"
                            : "index,follow"
                    }
                    name="robots"
                />
                <link
                    as="font"
                    crossOrigin="anonymous"
                    href="/static/fonts/darkmode_w_darkmodeonrg.woff2"
                    rel="preload"
                    type="font/woff2"
                />
                <link href="https://images.ctfassets.net" rel="preconnect" />
                <link href="https://images.ctfassets.net" rel="dns-prefetch" />
                {seo.canonicalUrl && (
                    <link href={seo.canonicalUrl} rel="canonical" />
                )}
            </Helmet>
            {children}
        </>
    );
};

export default PageHead;
