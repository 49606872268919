import { ThemeFactory, ThemeProviderCustom } from "@2po/component-library";
import { PageHeaderColorProvider } from "@contexts";
import React, { ReactNode } from "react";
import LayoutDefault from "./LayoutDefault/LayoutDefault";

export interface LayoutInterface {
    children: ReactNode;
    pageContext?: {
        noHeaderAndFooter: boolean;
    };
}

const Layout = ({ children, pageContext }: LayoutInterface): JSX.Element => {
    const theme = ThemeFactory({
        spacer: {
            800: "8rem",
        },
    });

    return (
        <ThemeProviderCustom theme={theme}>
            <PageHeaderColorProvider>
                {!pageContext?.noHeaderAndFooter ? (
                    <LayoutDefault>{children}</LayoutDefault>
                ) : (
                    <>{children}</>
                )}
            </PageHeaderColorProvider>
        </ThemeProviderCustom>
    );
};

export default Layout;
