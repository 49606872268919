import { graphql } from "gatsby";
import React from "react";
import { Divider } from "@components";
import { IContentDivider } from "@types";

export interface ContentfulDividerProps {
    content: IContentDivider;
    className?: string;
    width?: "screen" | "container";
}

const ContentfulDivider = ({
    content,
    width = "screen",
    className,
}: ContentfulDividerProps) => (
    <Divider className={className} color={content?.color?.name} width={width} />
);

export default ContentfulDivider;

export const query = graphql`
    fragment ContentDividerData on ContentfulContentDivider {
        color {
            name
        }
    }
`;
