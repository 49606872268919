import {
    Background,
    breakpointUp,
    Column,
    LogoPattern,
    spacer,
    zIndex,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import ContentfulHeader from "../Contentful/ContentfulHeader/ContentfulHeader";

export const StyledBackground = styled(Background)`
    display: flex;
    flex-direction: column;
    padding-top: ${spacer(300)};
    position: relative;

    ${breakpointUp(
        "l",
        css`
            gap: ${spacer(200)};
            flex-direction: row;
            justify-content: space-between;
            padding-top: ${spacer(400)};
        `
    )}
`;

export const StyledLogoPatternWrapper = styled.div`
    width: 100%;
`;

export const StyledContentfulHeader = styled(ContentfulHeader)`
    margin-bottom: ${spacer(200)};
`;

export const StyledLogoPattern = styled(LogoPattern)`
    width: 75%;
    margin-right: calc(((100vw - 100%) / -2));
    margin-left: auto;
    z-index: ${zIndex("base")};
    position: relative;

    ${breakpointUp(
        "l",
        css`
            width: 100%;
            margin-right: calc(((50vw - 100%) / -2) - ${spacer(150)});
            height: 100%;
            justify-content: flex-end;
        `
    )};
`;

export const StyledColumn = styled(Column)`
    z-index: ${zIndex("base")};
    position: relative;
`;
