import {
    Colors,
    Link,
    Spacers,
    TextAligns,
    Typography,
} from "@2po/component-library";
import { Image } from "@components";
import { IContentImage } from "@types";
import React, { CSSProperties } from "react";
import { ContentfulImageWrapper } from "./ContentfulImage.styled";

export interface ContentfulImageInterface {
    content: IContentImage;
    showCaption?: boolean;
    className?: string;
    captionColor?: Colors;
    captionAlign?: TextAligns;
    gap?: Spacers;
    style?: CSSProperties;
    loading?: "eager" | "lazy";
    hasCta?: boolean;
}

const ContentfulImage = ({
    content,
    showCaption = false,
    gap = 75,
    captionColor,
    captionAlign = "center",
    className,
    style,
    loading = "lazy",
    hasCta = true,
}: ContentfulImageInterface) => {
    if (!content?.media) {
        return null;
    }

    const { media, caption, link } = content;

    let imageComponent = (
        <Image
            alt={media.description || caption || "Contentful image"}
            className={className}
            loading={loading}
            media={media}
            style={style}
        />
    );

    if (hasCta && !!link) {
        const { externalLink, internalLink, label } = link;

        const navigationLink = internalLink
            ? `/${internalLink.slug}` || ""
            : externalLink
            ? externalLink
            : "";

        imageComponent = (
            <Link href={navigationLink} name={label}>
                {imageComponent}
            </Link>
        );
    }

    if (!showCaption) {
        return imageComponent;
    }

    return (
        <ContentfulImageWrapper gap={gap}>
            {imageComponent}
            {showCaption && caption && (
                <Typography color={captionColor} textAlign={captionAlign}>
                    {caption}
                </Typography>
            )}
        </ContentfulImageWrapper>
    );
};

export default ContentfulImage;
