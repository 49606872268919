import { previewMapper } from "@utils";
import { ContentfulClientApi, createClient } from "contentful";
import { useEffect, useState } from "react";

interface ClientInterface {
    space?: string;
    environment?: string;
    host?: string;
    accessToken: string;
}

const useClient = ({
    space = process.env.GATSBY_CF_SPACE_ID,
    environment = process.env.GATSBY_CF_ENVIRONMENT || "master",
    host = process.env.GATSBY_CF_PREVIEW_HOST || "preview.contentful.com",
    accessToken,
}: ClientInterface) => {
    const [client, setClient] = useState<ContentfulClientApi>();

    const initClient = async () => {
        if (!space) return null;

        const newClient: ContentfulClientApi = await createClient({
            space,
            environment,
            host,
            accessToken,
        });

        setClient(newClient);
    };

    const getEntry = async (
        id: string,
        query: Record<string, unknown> = {},
        include = 10
    ) => {
        if (!client) {
            return initClient();
        }

        const entry = await client.getEntry(id, { include, ...query });

        return previewMapper(entry);
    };

    const getEntries = async (
        query: Record<string, unknown> = {},
        include = 10
    ) => {
        if (!client) {
            return;
        }

        const entries = await client.getEntries({
            include,
            ...query,
        });

        return previewMapper(entries.items);
    };

    useEffect(() => {
        if (!client) initClient();
    }, []);

    return { client, getEntry, getEntries };
};

export default useClient;
