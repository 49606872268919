import {
    breakpointDown,
    breakpointUp,
    color,
    spacer,
    Typography,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import { SidebarItemProps } from "./SidebarItem";

export const StyledTitle = styled(Typography)<{ active?: boolean }>`
    font-weight: ${({ active }) => (active ? "bold" : "normal")};

    ${breakpointDown(
        "m",
        css`
            font-size: ${spacer(150)};
        `
    )}
`;

export const StyledSidebarItem = styled.button<Partial<SidebarItemProps>>`
    all: unset; // keep at top

    cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
    display: flex;
    gap: ${spacer(150)};
    align-items: center;
`;

export const ImageWrapper = styled.div<Partial<SidebarItemProps>>`
    width: ${spacer(200)};
    height: ${spacer(200)};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) =>
        backgroundColor && color(backgroundColor)};

    ${breakpointDown(
        "m",
        css`
            img {
                width: ${spacer(100)};
                height: ${spacer(100)};
            }
        `
    )}

    ${breakpointUp(
        "m",
        css`
            width: ${spacer(400)};
            height: ${spacer(400)};
        `
    )}
`;
