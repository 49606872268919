import { breakpointUp, Column, spacer } from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledColumn = styled(Column)`
    height: 100%;
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacer(100)};

    ${breakpointUp(
        "m",
        css`
            gap: ${spacer(250)};
        `
    )}
`;
