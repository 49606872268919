import { Colors } from "@2po/component-library";

// Try to only use this function on section level
export const handleTextColorByBackground = (
    backgroundColor: Colors = "white"
) => {
    if (backgroundColor === "black") {
        return "white";
    }

    return "black";
};

export const handleHighlightColorByBackground = (
    backgroundColor: Colors = "white"
) => {
    if (backgroundColor === "primary" || backgroundColor === "secondary") {
        return "black";
    }

    return undefined;
};

export const trimSlashes = (str: string) =>
    str
        ?.split("/")
        .filter((v) => v !== "")
        .join("/");
