import {
    breakpointDown,
    breakpointUp,
    Grid,
    spacer,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import ContentfulImage from "../ContentfulImage/ContentfulImage";

export const StyledGrid = styled(Grid)`
    height: fit-content;
    position: relative;

    ${breakpointUp(
        "l",
        css`
            direction: rtl;

            > * {
                direction: ltr;
            }
        `
    )};
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacer(500)};
    padding: ${spacer(500)} 0px;
`;

export const StyledContentfulImage = styled(ContentfulImage)`
    ${breakpointDown(
        "l",
        css`
            width: 100vw;
            max-height: 280px;
            margin-left: calc((100vw - 100%) / -2);
            margin-right: calc((100vw - 100%) / -2);
        `
    )};

    ${breakpointUp(
        "l",
        css`
            height: 100%;
            position: absolute !important;
            left: calc(${spacer(400)} * -1);
            top: 0;
            width: calc(50vw - ${spacer(200)});
            height: 100%;
        `
    )}

    ${breakpointUp(
        "xl",
        css`
            left: calc((100vw - 1536px) / -2);
            max-height: 1250px;
        `
    )}
`;
