import { ISectionHeader } from "@types";
import {
    handleHighlightColorByBackground,
    handleTextColorByBackground,
} from "@utils";
import { graphql } from "gatsby";
import React from "react";
import {
    StyledBackground,
    StyledHeader,
    StyledLogoPattern,
    StyledLogoPatternWrapper,
} from "./ContentfulSectionHeader.styled";

export interface ContentfulSectionHeaderInterface {
    className?: string;
    content: ISectionHeader;
}

const ContentfulSectionHeader = ({
    content,
    className,
}: ContentfulSectionHeaderInterface) => {
    if (!content) {
        return null;
    }

    const { color, label, link, title, pattern } = content;

    const highlightColor = handleHighlightColorByBackground(color?.name);

    return (
        <StyledBackground className={className} color={color?.name}>
            <StyledHeader
                eyebrow={label}
                eyebrowColor={highlightColor}
                headerType="h1"
                link={link}
                linkColor={highlightColor}
                linkColorHover={highlightColor}
                textColor={handleTextColorByBackground(color?.name)}
                title={title}
            />
            {pattern && (
                <StyledLogoPatternWrapper>
                    <StyledLogoPattern
                        repeatX={4}
                        repeatY={5}
                        slanted={pattern.slanted}
                    />
                </StyledLogoPatternWrapper>
            )}
        </StyledBackground>
    );
};

export default ContentfulSectionHeader;

export const query = graphql`
    fragment SectionHeaderData on ContentfulSectionHeader {
        label
        title
        link {
            ...ContentCtaData
        }
        color {
            name
        }
        pattern {
            ...ContentLogoPatternData
        }
    }
`;
