import { PageHead } from "@components";
import { IMetadata } from "@types";
import React, { ReactNode } from "react";

export interface HeadInterface {
    data?: {
        page: IMetadata;
    };
    location?: {
        pathname: string;
    };
}

interface SEOInterface extends HeadInterface {
    title?: string;
    description?: string;
    children?: ReactNode;
    noIndexNoFollow?: boolean;
}

const SEO = ({
    children,
    location,
    title,
    description,
    data,
    noIndexNoFollow = false,
}: SEOInterface): JSX.Element => (
    <PageHead
        description={
            description || data?.page?.metaDescription?.metaDescription
        }
        noIndexNoFollow={noIndexNoFollow}
        pathname={location?.pathname}
        title={title || data?.page?.metaTitle}
    >
        {children}
    </PageHead>
);

export default SEO;
