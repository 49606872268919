import { CaseSpecTypes } from "@types";

export const getTopicsByTypename = (
    topics: CaseSpecTypes[],
    typename: string
) => topics?.filter(({ __typename }) => __typename === typename);

export const getSpecValues = (
    specs: CaseSpecTypes[],
    typename: string,
    key: string
) =>
    getTopicsByTypename(specs, typename)
        ?.map((spec) => (spec as any)[key])
        ?.filter((v) => v);
