import { Column, Figure, Grid, Typography } from "@2po/component-library";
import { DefaultBackground } from "@components";
import { ISectionFigures } from "@types";
import { graphql } from "gatsby";
import React from "react";

interface ContentfulSectionFiguresProps {
    content: ISectionFigures;
    className?: string;
}

const ContentfulSectionFigures = ({
    content,
    className,
}: ContentfulSectionFiguresProps) => {
    if (!content?.figures?.length) {
        return null;
    }

    const { color, figures, title } = content;

    return (
        <DefaultBackground className={className} color={color?.name}>
            <Grid>
                <Column lSpan={2} xlSpan={3} xsSpan="full">
                    <Typography fontWeight="bold" type="h4">
                        {title}
                    </Typography>
                </Column>
                <Column
                    lSpan={4}
                    lStart={3}
                    mSpan={3}
                    mStart={2}
                    xlSpan={7}
                    xlStart={4}
                    xsSpan="full"
                >
                    <Grid gapCol={300}>
                        {figures.map((figure, index) => {
                            const { caption, value } = figure;

                            if (!caption || !value) return null;

                            return (
                                <Column
                                    key={`${figure.id}-figure-${index}`}
                                    sSpan="half"
                                    xsSpan="full"
                                >
                                    <Figure copy={caption} title={value} />
                                </Column>
                            );
                        })}
                    </Grid>
                </Column>
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionFigures;

export const query = graphql`
    fragment SectionFiguresData on ContentfulSectionFigures {
        title
        figures {
            ...GeneralModelData
            ...HighlightedValueData
        }
        color {
            name
        }
    }
    fragment HighlightedValueData on ContentfulContentHighlightedValue {
        caption
        value
    }
`;
