import { breakpointUp, spacer, Typography } from "@2po/component-library";
import { DefaultBackground } from "@components/Defaults";
import styled, { css } from "styled-components";

export const StyledDefaultBackground = styled(DefaultBackground)`
    display: flex;
    flex-direction: column;
    gap: ${spacer(200)};

    ${breakpointUp(
        "m",
        css`
            gap: ${spacer(500)};
        `
    )};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacer(250)};

    ${breakpointUp(
        "m",
        css`
            flex-direction: row-reverse;
            gap: ${spacer(400)};
            flex: 1 1 0px;

            > * {
                width: 100%;
            }
        `
    )};
`;

export const StyledSubtitle = styled(Typography)`
    ${breakpointUp(
        "m",
        css`
            text-align: right;
        `
    )};
`;

export const OfficesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacer(200)};

    ${breakpointUp(
        "m",
        css`
            gap: ${spacer(400)};
        `
    )};
`;

export const OfficeBlock = styled.div`
    display: flex;
    flex: 1 1 0px;
    gap: ${spacer(400)};

    > * {
        width: 100%;
    }
`;

export const StyledOfficeName = styled(Typography)`
    ${breakpointUp(
        "m",
        css`
            text-align: right;
            line-height: ${spacer(150)};
        `
    )};
`;

export const OfficeInfo = styled.div`
    display: flex;
    flex-direction: column;
`;
