import {
    breakpointDown,
    breakpointUp,
    CardTextual,
    spacer,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import DefaultBackground from "../../Defaults/Background/Background";
import Divider from "../../Divider/Divider";

export const StyledDefaultBackground = styled(DefaultBackground)`
    display: flex;
    flex-direction: column;

    ${breakpointUp(
        "m",
        css`
            padding-bottom: 0px;
        `
    )}
`;

export const RowContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${spacer(300)};
    gap: ${spacer(300)};

    ${breakpointUp(
        "m",
        css`
            flex-direction: row;
            flex: 1 1 0px;
            gap: 0px;
        `
    )}

    ${breakpointUp(
        "l",
        css`
            padding-top: ${spacer(500)};
        `
    )}
`;

export const StyledCardTextual = styled(CardTextual)`
    width: 100%;

    ${breakpointUp(
        "m",
        css`
            padding-bottom: ${spacer(300)};
            padding-left: ${spacer(100)};
            padding-right: ${spacer(100)};
        `
    )}

    ${breakpointUp(
        "l",
        css`
            min-height: 200px;
        `
    )}
`;

export const StyledDivider = styled(Divider)`
    ${breakpointDown(
        "l",
        css`
            display: none;
        `
    )}
`;
