import { breakpointUp, Button, spacer } from "@2po/component-library";
import styled, { css } from "styled-components";

export const ContactFormComponent = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${spacer(250)} ${spacer(200)};
    align-items: flex-end;

    ${breakpointUp(
        "s",
        css`
            grid-template-columns: 1fr 1fr;
        `
    )};

    ${breakpointUp(
        "m",
        css`
            grid-template-columns: 1fr;
        `
    )};
`;

export const StyledButton = styled(Button)`
    ${breakpointUp(
        "m",
        css`
            margin-top: ${spacer(100)};
        `
    )};
`;
