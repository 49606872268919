import { LogoPatternHighlight } from "@2po/component-library";
import { IContentLogoPattern } from "@types";
import { graphql } from "gatsby";
import React from "react";

interface ContentfulContentLogoPatternProps {
    content: IContentLogoPattern;
    className?: string;
}

const ContentfulContentLogoPattern = ({
    content,
    className,
}: ContentfulContentLogoPatternProps) => {
    if (!content?.color) {
        return null;
    }

    const { color, highlightColor } = content;

    return (
        <LogoPatternHighlight
            className={className}
            color={color?.name}
            highlightColor={highlightColor?.name}
        />
    );
};

export default ContentfulContentLogoPattern;

export const query = graphql`
    fragment ContentLogoPatternData on ContentfulContentLogoPattern {
        color {
            name
        }
        highlightColor {
            name
        }
        slanted
    }
`;
