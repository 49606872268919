import { breakpointUp, Link, spacer, Typography } from "@2po/component-library";
import styled, { css } from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacer(200)};
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: ${spacer(50)};
    align-items: center;

    ${breakpointUp(
        "l",
        css`
            flex-direction: column;
            align-items: flex-start;
        `
    )}
`;

export const StyledTopic = styled(Typography)`
    min-width: 150px;
`;

export const StyledLink = styled(Link)`
    text-decoration: underline;
`;
