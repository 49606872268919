import { BackgroundInterface } from "@2po/component-library";
import React from "react";
import { StyledBackground } from "./Background.styled";

export interface DefaultBackgroundProps extends Partial<BackgroundInterface> {
    size?: "small" | "medium" | "";
}

const DefaultBackground = ({
    size = "medium",
    children,
    ...props
}: DefaultBackgroundProps): JSX.Element => (
    <StyledBackground size={size} {...props}>
        {children}
    </StyledBackground>
);

export default DefaultBackground;
