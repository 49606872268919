import { Colors } from "@2po/component-library";
import React from "react";
import { StyledDivider } from "./Divider.styled";

export interface DividerProps {
    color?: Colors;
    className?: string;
    width?: "screen" | "container";
}

const Divider = ({
    color = "black",
    width = "screen",
    className,
}: DividerProps) => (
    <StyledDivider className={className} color={color} width={width} />
);

export default Divider;
