import { Colors } from "@2po/component-library";
import React, { ReactNode, createContext, useMemo, useState } from "react";

type PageHeaderColorProviderState = {
    pageHeaderColor: Colors | null;
    setPageHeaderColor: (color: Colors | null) => void;
};

const PageHeaderColorContext =
    createContext<PageHeaderColorProviderState | null>(null);

type PageHeaderColorProviderProps = {
    children: ReactNode;
};

const PageHeaderColorProvider = ({
    children,
}: PageHeaderColorProviderProps) => {
    const [pageHeaderColor, setPageHeaderColor] = useState<Colors | null>(null);

    const value = useMemo(
        () => ({
            pageHeaderColor,
            setPageHeaderColor,
        }),
        [pageHeaderColor]
    );

    return (
        <PageHeaderColorContext.Provider value={value}>
            {children}
        </PageHeaderColorContext.Provider>
    );
};

export { PageHeaderColorContext, PageHeaderColorProvider };
