import {
    breakpointDown,
    breakpointUp,
    spacer,
    zIndex,
} from "@2po/component-library";
import { containerBreakLeft, containerBreakRight } from "@utils";
import Masonry from "react-masonry-css";
import styled, { css } from "styled-components";
import { MasonryTypes } from "./CasesMasonry";

export const StyledMasonry = styled(Masonry)`
    display: flex;
    gap: ${spacer(300)};

    // className as the masonry package supports this and makes the styling more specific
    > .project-masonry-columns {
        display: flex;
        flex-direction: column;
        gap: ${spacer(300)};

        > * {
            min-height: 500px;
        }

        ${({ type }: { type: MasonryTypes }) => {
            if (!type || type === "level") return null;

            const offsetStyling = css`
                ${breakpointUp(
                    "l",
                    css`
                        margin-top: ${spacer(800)};
                    `
                )}
            `;

            if (type === "ascending") {
                return css`
                    :first-child {
                        ${offsetStyling}
                    }
                `;
            }

            if (type === "descending") {
                return css`
                    :last-child {
                        ${offsetStyling}
                    }
                `;
            }
        }}

        ${breakpointUp(
            "l",
            css`
                gap: ${spacer(800)};
            `
        )}

        ${breakpointDown(
            "l",
            css`
                z-index: ${zIndex("base")};

                > :nth-child(odd) {
                    ${containerBreakLeft}
                    z-index: ${zIndex("base")};
                }

                > :nth-child(even) {
                    ${containerBreakRight}
                }
            `
        )};
    }
`;
