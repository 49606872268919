import { Link, Typography } from "@2po/component-library";
import { ISectionOffices } from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import {
    HeaderWrapper,
    OfficeBlock,
    OfficeInfo,
    OfficesWrapper,
    StyledDefaultBackground,
    StyledOfficeName,
    StyledSubtitle,
} from "./ContentfulSectionOffices.styled";

const ContentfulSectionOffices = ({
    content,
    className,
}: {
    content: ISectionOffices;
    className?: string;
}) => {
    if (!content) {
        return null;
    }

    const { color, highlightColor, offices, title, subtitle } = content;
    const textColor = handleTextColorByBackground(color?.name);
    const highlightTextColor = highlightColor?.name;

    return (
        <StyledDefaultBackground className={className} color={color?.name}>
            <HeaderWrapper>
                <Typography color={textColor} type="h3">
                    {title}
                </Typography>
                <StyledSubtitle color={highlightTextColor} type="h4">
                    {subtitle}
                </StyledSubtitle>
            </HeaderWrapper>
            {!!offices?.length && (
                <OfficesWrapper>
                    {offices.map(
                        (
                            {
                                name,
                                site,
                                street,
                                number,
                                addition,
                                email,
                                phone,
                                postalCode,
                                city,
                            },
                            index
                        ) => {
                            const officeInfoTextType = "largeBody";

                            return (
                                <OfficeBlock key={`${name}-${index}`}>
                                    {name && (
                                        <StyledOfficeName
                                            color={highlightTextColor}
                                            type="label"
                                        >
                                            {name}
                                        </StyledOfficeName>
                                    )}
                                    <OfficeInfo>
                                        {site && (
                                            <Typography
                                                color={textColor}
                                                type={officeInfoTextType}
                                            >
                                                {site}
                                            </Typography>
                                        )}
                                        {street && (
                                            <Typography
                                                color={textColor}
                                                type={officeInfoTextType}
                                            >{`${street || ""} ${
                                                number || ""
                                            } ${addition || ""}`}</Typography>
                                        )}
                                        {postalCode && city && (
                                            <Typography
                                                color={textColor}
                                                type={officeInfoTextType}
                                            >{`${postalCode} ${city}`}</Typography>
                                        )}
                                        {email && (
                                            <Link
                                                color={highlightTextColor}
                                                href={`mailto:${email}`}
                                                type={officeInfoTextType}
                                            >
                                                {email}
                                            </Link>
                                        )}
                                        {phone && (
                                            <Link
                                                color={highlightTextColor}
                                                href={`tel:${phone}`}
                                                type={officeInfoTextType}
                                            >
                                                {phone}
                                            </Link>
                                        )}
                                    </OfficeInfo>
                                </OfficeBlock>
                            );
                        }
                    )}
                </OfficesWrapper>
            )}
        </StyledDefaultBackground>
    );
};

export default ContentfulSectionOffices;

export const query = graphql`
    fragment SectionOfficesData on ContentfulSectionOffices {
        title
        subtitle
        offices {
            ...OfficeData
        }
        color {
            name
        }
        highlightColor {
            name
        }
    }
    fragment OfficeData on ContentfulOffice {
        name
        site
        street
        number
        addition
        postalCode
        city
        email
        phone
    }
`;
