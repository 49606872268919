import YouTube from "react-youtube";

import { IContentVideo } from "@types";
import { graphql } from "gatsby";
import React from "react";
import { StyledVideoWrapper } from "./ContentfulVideo.styled";

interface MapperProps {
    content: IContentVideo;
    className?: string;
}

const ContentfulVideo = ({ className, content }: MapperProps) => {
    if (!content) {
        return null;
    }

    const { youtubeVideoId, altText, id } = content;

    return (
        <StyledVideoWrapper>
            <YouTube
                className={className}
                id={id}
                opts={{
                    width: "100%",
                    height: "100%",
                    playerVars: {
                        loop: 0,
                        rel: 0,
                        color: "white",
                    },
                }}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    border: "none",
                }}
                title={altText}
                videoId={youtubeVideoId}
            />
        </StyledVideoWrapper>
    );
};

export default ContentfulVideo;

export const query = graphql`
    fragment ContentVideoData on ContentfulContentVideo {
        youtubeVideoId
        altText
    }
`;
