import { Grid } from "@2po/component-library";
import {
    ContentfulImage,
    ContentfulRichText,
    DefaultBackground,
    SidebarItem,
} from "@components";
import { ISectionSidebar } from "@types";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Sidebar, StyledColumn } from "./ContentfulSectionSidebar.styled";

interface ContentfulSectionSidebarProps {
    content: ISectionSidebar;
    className?: string;
}

const ContentfulSectionSidebar = ({
    content,
    className,
}: ContentfulSectionSidebarProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    if (!content) return null;

    const { color, items, highlightColor } = content;

    useEffect(() => {
        //Take the anchor ref in the URL and decode it so we can find it in the sidebar items and set it as active
        //TODO: once the font issue is resolved, rework the below to add it to the url on click. Now it has to be added manually
        const activeItem = decodeURI(window.location.hash);

        if (activeItem) {
            let activeIndex = 0;
            items.forEach((item, index) => {
                const strippedAlphanumeric = item.title.replace(
                    /[^\w\s]/gi,
                    ""
                );
                const titleWithHyphen = strippedAlphanumeric.replace(" ", "-");
                if (
                    titleWithHyphen.toLowerCase() ===
                    activeItem.replace("#", "").toLowerCase()
                ) {
                    activeIndex = index;
                }
            });
            setActiveIndex(activeIndex);
        }
    }, []);

    return (
        <DefaultBackground className={className} color={color?.name}>
            <Grid gapRowS={250} gapRowXs={250} l={3} m={3} xl={3}>
                <StyledColumn alignSelf="center" mSpan="1" xsSpan="full">
                    <Sidebar>
                        {items?.map((item, index) => (
                            <SidebarItem
                                active={index === activeIndex}
                                activeColor={highlightColor?.name}
                                backgroundColor={item.image.color?.name}
                                image={
                                    <ContentfulImage
                                        content={item.image}
                                        hasCta={false}
                                    />
                                }
                                key={item.title}
                                onClick={() => {
                                    setActiveIndex(index);
                                    // const hashtag = item?.title?.replace(
                                    //     /[^\w\s]/gi,
                                    //     ""
                                    // );
                                    // window.location.href = `#${hashtag?.replace(
                                    //     " ",
                                    //     "-"
                                    // )}`;
                                }}
                                title={item.title}
                            />
                        ))}
                    </Sidebar>
                </StyledColumn>
                <StyledColumn alignSelf="center" mSpan="2" xsSpan="full">
                    <ContentfulRichText content={items[activeIndex]?.content} />
                </StyledColumn>
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionSidebar;

export const query = graphql`
    fragment SectionSidebarData on ContentfulSectionSidebar {
        items {
            ...SectionSidebarItemData
        }
        highlightColor {
            name
        }
        color {
            name
        }
    }
    fragment SectionSidebarItemData on ContentfulContentSidebarItem {
        title
        image {
            ...GeneralImageData
            color {
                name
            }
            media {
                gatsbyImageData(width: 44, placeholder: BLURRED)
                description
            }
        }
        content {
            ...ContentRichTextData
        }
    }
`;
