import { CardCarousel, Colors } from "@2po/component-library";
import {
    ContentfulImage,
    ContentfulVideo,
    DefaultBackground,
} from "@components";
import "@styles/swiper.css";
import {
    CarouselItemType,
    IContentImage,
    IContentVideo,
    ISectionCarousel,
    ISolution,
} from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import { StyledCarousel } from "./ContentfulSectionCarousel.styled";

interface ContentfulSectionCarouselProps {
    content: ISectionCarousel;
    className?: string;
}

const CarouselBodyMapper = ({
    item,
    textColor,
}: {
    item: CarouselItemType;
    textColor: Colors;
}) => {
    if (item.__typename === "ContentfulSolution") {
        const typedItem = item as ISolution;
        const { image, id, description } = typedItem;

        return (
            <CardCarousel
                alt={description?.description || image?.media?.description}
                image={() =>
                    image && (
                        <ContentfulImage
                            captionAlign="center"
                            captionColor={textColor}
                            content={image}
                            gap={150}
                        />
                    )
                }
                key={id}
            />
        );
    }

    if (item.__typename === "ContentfulContentImage") {
        const image = item as IContentImage;

        return (
            <CardCarousel
                alt={image?.media?.description}
                image={() =>
                    image && (
                        <ContentfulImage
                            captionAlign="center"
                            captionColor={textColor}
                            content={image}
                            gap={150}
                        />
                    )
                }
                key={image.id}
            />
        );
    }

    if (item.__typename === "ContentfulContentVideo") {
        const video = item as IContentVideo;
        return (
            <CardCarousel
                alt={video?.altText}
                image={() => video && <ContentfulVideo content={video} />}
                key={video.id}
            />
        );
    }
    return null;
};

const ContentfulSectionCarousel = ({
    content,
    className,
}: ContentfulSectionCarouselProps) => {
    if (!content?.items?.length) {
        return null;
    }

    const { color, items } = content;

    const textColor = handleTextColorByBackground(color?.name);

    return (
        <DefaultBackground className={className} color={color?.name}>
            <StyledCarousel
                classNameCarousel="contentfulCarousel"
                headerColor={textColor}
            >
                {items.map((item) => (
                    <CarouselBodyMapper
                        item={item}
                        key={`${item.id}-carousel-item`}
                        textColor={textColor}
                    />
                ))}
            </StyledCarousel>
        </DefaultBackground>
    );
};

export default ContentfulSectionCarousel;

export const query = graphql`
    fragment SectionCarouselData on ContentfulSectionCarousel {
        id
        type
        __typename
        color {
            name
        }
        items {
            ...GeneralModelData
            ... on ContentfulContentVideo {
                contentful_id
                youtubeVideoId
                altText
            }
            ... on ContentfulContentImage {
                ...GeneralImageData
                media {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        breakpoints: [360, 544, 640, 1024, 1536]
                    )
                    description
                }
                link {
                    ...ContentCtaData
                }
            }

            ... on ContentfulSolution {
                description {
                    description
                }
                category
                image {
                    ...GeneralImageData
                    media {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: BLURRED
                            width: 130
                        )
                        description
                    }
                }
            }
        }
    }
`;
