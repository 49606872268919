import { Colors, FontTypes, Header } from "@2po/component-library";
import { IContentCta } from "@types";
import { getLinkedHref } from "@utils";
import React from "react";

interface ContentfulHeaderInterface {
    className?: string;
    eyebrowColor?: Colors;
    linkColor?: Colors;
    linkColorHover?: Colors;
    textColor?: Colors;
    eyebrow?: string[];
    link?: IContentCta;
    title: string;
    headerType?: FontTypes;
}

const ContentfulHeader = ({
    className,
    eyebrowColor,
    linkColor,
    linkColorHover,
    textColor,
    eyebrow,
    link,
    title,
    headerType,
}: ContentfulHeaderInterface) => {
    if (!title) {
        return null;
    }
    const ctalink = getLinkedHref(link);
    return (
        <Header
            className={className}
            eyebrow={eyebrow}
            eyebrowColor={eyebrowColor}
            headerColor={textColor}
            headerType={headerType}
            linkColor={linkColor}
            linkColorHover={linkColorHover}
            linkHref={ctalink}
            linkLabel={link?.label}
            title={title}
        />
    );
};

export default ContentfulHeader;
