import {
    ContentfulCallToAction,
    ContentfulContentLogoPattern,
    ContentfulDivider,
    ContentfulImage,
    ContentfulRichText,
    ContentfulSectionCarousel,
    ContentfulSectionCarouselChain,
    ContentfulSectionCases,
    ContentfulSectionColumns,
    ContentfulSectionContact,
    ContentfulSectionCtaBanner,
    ContentfulSectionDuplex,
    ContentfulSectionFigures,
    ContentfulSectionGrid,
    ContentfulSectionHeader,
    ContentfulSectionImageText,
    ContentfulSectionInfo,
    ContentfulSectionOffices,
    ContentfulSectionQuote,
    ContentfulSectionSidebar,
    ContentfulSectionTabs,
    ContentfulVideo,
    ErrorBoundary,
} from "@components";
import { IEntry } from "@types";
import { isProduction } from "@utils";
import React, { ReactNode } from "react";
import ContentfulSectionHighlight from "../ContentfulSectionHighlight/ContentfulSectionHighlight";
import ContentfulSectionRichText from "../ContentfulSectionRichText/ContentfulSectionRichText";
import ContentfulService from "../ContentfulService/ContentfulService";

export interface MapperPropsContent {
    id: string;
    __typename: string;
    fields: unknown;
}

interface MapperProps {
    content: any;
    additionalProps?: unknown;
    className?: string;
}

interface WrapProps {
    children: ReactNode;
}

const Wrap = ({ children }: WrapProps) => (
    <ErrorBoundary message="ERROR: This will be hidden when live. Please report!">
        {children}
    </ErrorBoundary>
);

export const Mapper = ({
    content,
    additionalProps,
    className,
}: MapperProps) => {
    const contentType = content?.["__typename"];

    const modelProps = {
        ...(typeof additionalProps === "object" ? additionalProps : {}),
        content,
        className,
    };

    switch (contentType) {
        case "ContentfulContentVideo":
            return <ContentfulVideo {...modelProps} />;
        case "ContentfulSectionHighlight":
            return <ContentfulSectionHighlight {...modelProps} />;
        case "ContentfulSectionCtaBanner":
            return <ContentfulSectionCtaBanner {...modelProps} />;
        case "ContentfulContentRichText":
            return <ContentfulRichText {...modelProps} />;
        case "ContentfulContentCta":
            return <ContentfulCallToAction {...modelProps} />;
        case "ContentfulSectionColumns":
            return <ContentfulSectionColumns {...modelProps} />;
        case "ContentfulContentDivider":
            return <ContentfulDivider {...modelProps} />;
        case "ContentfulSectionContact":
            return <ContentfulSectionContact {...modelProps} />;
        case "ContentfulSectionHeader":
            return <ContentfulSectionHeader {...modelProps} />;
        case "ContentfulContentImage":
            return <ContentfulImage {...modelProps} />;
        case "ContentfulContentLogoPattern":
            return <ContentfulContentLogoPattern {...modelProps} />;
        case "ContentfulSectionCarousel":
            if (content.type === "Carousel chain")
                return <ContentfulSectionCarouselChain {...modelProps} />;
            return <ContentfulSectionCarousel {...modelProps} />;
        case "ContentfulSectionDuplex":
            return <ContentfulSectionDuplex {...modelProps} />;
        case "ContentfulSectionFigures":
            return <ContentfulSectionFigures {...modelProps} />;
        case "ContentfulSectionGrid":
            return <ContentfulSectionGrid {...modelProps} />;
        case "ContentfulSectionImageText":
            return <ContentfulSectionImageText {...modelProps} />;
        case "ContentfulSectionInfo":
            return <ContentfulSectionInfo {...modelProps} />;
        case "ContentfulSectionCases":
            return <ContentfulSectionCases {...modelProps} />;
        case "ContentfulSectionQuote":
            return <ContentfulSectionQuote {...modelProps} />;
        case "ContentfulSectionTabs":
            return <ContentfulSectionTabs {...modelProps} />;
        case "ContentfulSectionOffices":
            return <ContentfulSectionOffices {...modelProps} />;
        case "ContentfulSectionRichText":
            return <ContentfulSectionRichText {...modelProps} />;
        case "ContentfulSectionSidebar":
            return <ContentfulSectionSidebar {...modelProps} />;
        case "ContentfulService":
            return <ContentfulService {...modelProps} />;

        default: {
            if (isProduction()) {
                return null;
            }

            return <p>{`EMBEDDED_ENTRY - Type: ${contentType}`}</p>;
        }
    }
};

export const map = (content: IEntry[], props?: MapperProps): any => {
    if (!content?.length) {
        return null;
    }

    return content?.map((data) =>
        isProduction() ? (
            <Wrap key={data?.id}>
                <Mapper {...props} content={data} />
            </Wrap>
        ) : (
            <Mapper {...props} content={data} key={data?.id} />
        )
    );
};
