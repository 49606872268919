import { IContentCta } from "@types";

export const getLinkedHref = (link: IContentCta | undefined) =>
    link
        ? link.internalLink
            ? `/${link.internalLink.slug}` || ""
            : link.externalLink
            ? link.externalLink
            : ""
        : "/";
