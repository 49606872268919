import { Navigation, NavLink } from "@2po/component-library";
import { useWindowSize } from "@hooks";
import { mapNavigationLinks } from "@utils";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";

export interface NavigationInterface {
    className?: string;
}

const NavigationComponent = ({ className }: NavigationInterface) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useWindowSize();

    const { navigation } = useStaticQuery(graphql`
        query NavigationQuery {
            navigation: contentfulNavigation {
                links {
                    ...ContentCtaData
                }
            }
        }
    `);

    const links = mapNavigationLinks(navigation?.links, true, () =>
        setIsOpen(false)
    ) as NavLink[];

    if (!links) return null;

    const onMobileMenuClick = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <Navigation
            className={className}
            color="white"
            isOpen={isMobile && isOpen}
            links={links}
            logoLink="/"
            onMobileMenuClick={onMobileMenuClick}
        />
    );
};

export default NavigationComponent;
