import { Input } from "@2po/component-library";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { apiClient } from "@helpers";
import { useFormValidation } from "@hooks";
import { IForm } from "@types";
import { useFormik } from "formik";
import { graphql } from "gatsby";
import React, { useState } from "react";
import { ContactFormComponent, StyledButton } from "./ContentfulForm.styled";

const ContentfulForm = ({
    content,
    className,
}: {
    content: IForm;
    className?: string;
}) => {
    const {
        getValidationSchema,
        emailValidation,
        messageValidation,
        phoneValidation,
        booleanValidation,
    } = useFormValidation();

    const initialValues = content?.fields?.reduce(
        (prev, curr) => ({
            ...prev,
            [curr.key]: curr.type === "checkbox" ? false : "",
        }),
        {}
    );

    const schemaObject = content?.fields?.reduce((prev, curr) => {
        if (curr.required) {
            const getValidation = () => {
                if (curr.type === "email") {
                    return emailValidation();
                }

                if (curr.type === "tel") {
                    return phoneValidation();
                }
                if (curr.type === "checkbox") {
                    return booleanValidation();
                }

                return messageValidation();
            };

            return {
                ...prev,
                [curr.key]: getValidation(),
            };
        }
        return {
            ...prev,
        };
    }, {});

    const onSubmit = async (data: any) => {
        try {
            await apiClient({
                method: "POST",
                url: content.buttonDestination,
                data: { data },
            });
            setFormSubmitted(true);
            formik.resetForm();
        } catch (e: any) {
            throw Error("Error submitting form");
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: getValidationSchema(schemaObject),
        onSubmit,
    });

    const [formSubmitted, setFormSubmitted] = useState(false);

    if (!content) {
        return null;
    }

    const options: any = {
        renderMark: {
            [MARKS.BOLD]: (text: any) => <b>{text}</b>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <p>{children}</p>,
        },
    };

    return (
        <ContactFormComponent
            className={className}
            onSubmit={formik.handleSubmit}
        >
            {content?.fields?.map((input, index) => (
                <Input
                    errorMessage={
                        (formik.touched as any)[input.key] &&
                        (formik.errors as any)[input.key]
                    }
                    id={input.key}
                    key={`${input.id}-${index}`}
                    label={input.label}
                    name={input.key}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={input.placeholder}
                    required={input.required}
                    type={input.type}
                    value={(formik?.values as any)?.[input.key]}
                />
            ))}

            <StyledButton
                backgroundColor="black"
                color="white"
                icon="arrowRight"
                iconPosition="right"
                onClick={() => console.log(formik.values)}
                type="submit"
            >
                {formSubmitted && !Object.keys(formik.touched).length
                    ? "Submitted succesfully!"
                    : content?.buttonLabel}
            </StyledButton>
        </ContactFormComponent>
    );
};

export default ContentfulForm;

export const query = graphql`
    fragment FormData on ContentfulForm {
        fields: contentfulfields {
            key
            type
            required
            label
            labelstr {
                raw
                references {
                    ... on ContentfulPage {
                        slug
                    }
                }
            }
            placeholder
            errorMessage
        }
        buttonLabel
        buttonDestination
    }
`;
