import axios from "axios";

export const apiClient = ({
    method = "get",
    url,
    data = {},
    options = {},
    headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${process.env.GATSBY_FORM_BASIC_TOKEN}`,
    },
    responseType = "json",
    timeout = 30000,
}: any) =>
    url
        ? axios({
              method,
              url,
              data,
              options,
              headers,
              responseType,
              timeout,
          } as any)
        : null;
