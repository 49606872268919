import { Tag, breakpointUp, spacer } from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledTag = styled(Tag)`
    display: flex;

    gap: ${spacer(125)};
    flex-direction: column;
    align-items: center;
    zoom: 0.7;
    width: calc(40% - ${spacer(125)});
    text-align: center;

    ${breakpointUp(
        "m",
        css`
            width: unset;
            align-self: self-start;
        `
    )};
`;
