import { BlockCallToAction, Column, ColXsType } from "@2po/component-library";
import { DefaultBackground } from "@components";
import { IContentLogoPattern, ISectionCtaBanner } from "@types";
import {
    getLinkedHref,
    handleHighlightColorByBackground,
    handleTextColorByBackground,
} from "@utils";
import { graphql } from "gatsby";
import React from "react";
import { Mapper } from "../ContentfulModelMapper/ContentfulModelMapper";
import { StyledGrid } from "./ContentfulSectionCtaBanner.styled";

interface ContentfulSectionCtaBannerProps {
    content: ISectionCtaBanner;
    className?: string;
}

const ContentfulSectionCtaBanner = ({
    content,
    className,
}: ContentfulSectionCtaBannerProps) => {
    const { color, graphic, label, title, link, reverse } = content;

    const columnProps = {
        xsSpan: "full" as ColXsType,
        mSpan: "half" as ColXsType,
    };

    const highlightColor =
        (graphic as IContentLogoPattern)?.highlightColor?.name ||
        graphic?.color?.name ||
        handleHighlightColorByBackground(color?.name);

    return (
        <>
            <DefaultBackground className={className} color={color?.name}>
                <StyledGrid reverse={reverse}>
                    <Column alignSelf="center" {...columnProps}>
                        <BlockCallToAction
                            buttonColor={handleTextColorByBackground(
                                highlightColor
                            )}
                            buttonHref={link && getLinkedHref(link)}
                            buttonLabel={link?.label}
                            className={className}
                            color={highlightColor}
                            eyebrow={label}
                            title={title}
                            titleColor={handleTextColorByBackground(
                                color?.name
                            )}
                        />
                    </Column>
                    <Column alignSelf="center" {...columnProps}>
                        <Mapper
                            additionalProps={{
                                textColor: handleTextColorByBackground(
                                    color?.name
                                ),
                            }}
                            content={graphic}
                        />
                    </Column>
                </StyledGrid>
            </DefaultBackground>
        </>
    );
};

export default ContentfulSectionCtaBanner;

export const query = graphql`
    fragment SectionCtaBannerData on ContentfulSectionCtaBanner {
        label
        title
        link {
            ...GeneralModelData
            ...ContentCtaData
        }
        graphic {
            ...GeneralModelData
            ...ContentLogoPatternData
            ...ContentVideoData
            ... on ContentfulContentImage {
                ...GeneralImageData
                media {
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                }
                link {
                    ...ContentCtaData
                }
            }
        }
        color {
            name
        }
        reverse
    }
`;
