import { Divider } from "@2po/component-library";
import { containerBreak } from "@utils";
import styled, { css } from "styled-components";
import { DividerProps } from "./Divider";

export const StyledDivider = styled(Divider)<DividerProps>`
    ${({ width }) => {
        if (width === "container") {
            return css`
                width: 100%;
            `;
        }

        if (width === "screen") {
            return containerBreak;
        }
    }}
`;
