import { Colors, Flex, Icons, Spacers } from "@2po/component-library";
import { RichText } from "@components";
import { useWindowSize } from "@hooks";
import { IContentRichText } from "@types";
import { graphql } from "gatsby";
import React from "react";

interface ContentfulRichTextInterface {
    content?: IContentRichText;
    className?: string;
    gap?: Spacers;
    mobileGap?: Spacers;
    textColor?: Colors;
    icon?: Icons;
    iconColor?: Colors;
}

const ContentfulRichText = ({
    content,
    className,
    textColor,
    gap = 150,
    mobileGap = 100,
    icon,
    iconColor,
}: ContentfulRichTextInterface) => {
    const { isMobile } = useWindowSize();

    if (!content) {
        return null;
    }

    const { copy, alignment, color } = content;

    const colorToUse = color?.name || textColor;

    return (
        <Flex
            flexDirection={alignment === "inline" ? "row" : "column"}
            flexWrap={alignment === "inline" ? "wrap" : undefined}
            gap={isMobile ? mobileGap : gap}
        >
            <RichText
                className={className}
                color={colorToUse}
                copy={copy}
                icon={icon}
                iconColor={iconColor || colorToUse}
                textAlign={isMobile ? undefined : alignment}
            />
        </Flex>
    );
};

export default ContentfulRichText;

export const query = graphql`
    fragment ContentRichTextData on ContentfulContentRichText {
        alignment
        color {
            name
        }
        copy {
            raw
            references {
                ...GeneralModelData
                ...ContentCtaData
                ... on Node {
                    ... on ContentfulPage {
                        id
                        contentful_id
                        slug
                        __typename
                    }
                    ... on ContentfulContentVideo {
                        contentful_id
                        youtubeVideoId
                        altText
                    }
                    ... on ContentfulContentCta {
                        contentful_id
                    }
                    ... on ContentfulContentImage {
                        contentful_id
                        ...GeneralImageData
                        media {
                            gatsbyImageData(
                                width: 1100
                                placeholder: BLURRED
                                breakpoints: [360, 544, 640, 1024, 1536]
                            )

                            description
                        }
                        link {
                            ...ContentCtaData
                        }
                    }
                    ... on ContentfulService {
                        contentful_id
                        displayName
                        thumbnail {
                            media {
                                gatsbyImageData(width: 44, placeholder: BLURRED)
                            }
                        }
                        description {
                            raw
                        }
                    }
                }
            }
        }
    }
`;
