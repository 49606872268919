import {
    AuthorInfo,
    breakpointUp,
    Grid,
    Quote,
    spacer,
} from "@2po/component-library";
import styled, { css } from "styled-components";
import { DefaultBackground } from "../../Defaults";

export const StyledBackground = styled(DefaultBackground)`
    padding-bottom: 0px;
`;

export const StyledGrid = styled(Grid)`
    ${breakpointUp(
        "l",
        css`
            direction: rtl;
        `
    )}
`;

const largeTextAlignChange = breakpointUp(
    "l",
    css`
        text-align: end;
    `
);

export const StyledQuote = styled(Quote)`
    direction: ltr;

    ${breakpointUp(
        "l",
        css`
            padding-bottom: ${spacer(800)};
        `
    )};
`;

export const StyledAuthorInfo = styled(AuthorInfo)`
    ${largeTextAlignChange};

    padding-bottom: ${spacer(500)};
    text-align: left;
    direction: ltr;

    ${breakpointUp(
        "l",
        css`
            padding-bottom: 0px;
            height: 100%;
            text-align: left;
            direction: ltr;
        `
    )}
`;
