import { BlockCallToAction, Column, ColXsType } from "@2po/component-library";
import { DefaultBackground } from "@components";
import { PageHeaderColorContext } from "@contexts/PageHeaderColorContext";
import { ISectionImageText } from "@types";
import { getLinkedHref, handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React, { useContext } from "react";
import { Mapper } from "../ContentfulModelMapper/ContentfulModelMapper";
import { ImageWrapper, StyledGrid } from "./ContentfulSectionImageText.styled";

interface ContentfulSectionImageTextProps {
    content: ISectionImageText;
    className?: string;
}

const ContentfulSectionImageText = ({
    content,
    className,
}: ContentfulSectionImageTextProps) => {
    const { color, image, description, title, link, reverse } = content;

    const context = useContext(PageHeaderColorContext);
    const headerColor = context?.pageHeaderColor;

    const columnProps = {
        xsSpan: "full" as ColXsType,
    };

    const wideColumnProps = {
        mSpan: 2,
        lSpan: 2,
        xlSpan: 2,
    };

    const narrowColumnProps = {
        mSpan: 1,
        lSpan: 1,
        xlSpan: 1,
    };

    return (
        <>
            <DefaultBackground
                className={className}
                color={color?.name}
                size="small"
            >
                <StyledGrid l={3} m={3} reverse={reverse} xl={3}>
                    <Column
                        alignSelf="center"
                        {...columnProps}
                        {...wideColumnProps}
                    >
                        <BlockCallToAction
                            buttonColor="white"
                            buttonHoverColor={headerColor}
                            buttonHref={link && getLinkedHref(link)}
                            buttonLabel={link?.label}
                            className={className}
                            color="black"
                            description={description?.description}
                            title={title}
                            titleColor={handleTextColorByBackground(
                                color?.name
                            )}
                        />
                    </Column>
                    <Column
                        alignSelf="center"
                        {...columnProps}
                        {...narrowColumnProps}
                    >
                        {image && (
                            <ImageWrapper
                                background={image.color?.name}
                                reverse={reverse}
                            >
                                {
                                    <Mapper
                                        additionalProps={{ loading: "lazy" }}
                                        content={image}
                                    />
                                }
                            </ImageWrapper>
                        )}
                    </Column>
                </StyledGrid>
            </DefaultBackground>
        </>
    );
};

export default ContentfulSectionImageText;

export const query = graphql`
    fragment SectionImageTextData on ContentfulSectionImageText {
        title
        description {
            description
        }
        link {
            ...GeneralModelData
            ...ContentCtaData
        }
        image {
            ...GeneralModelData
            ... on ContentfulContentImage {
                ...GeneralImageData
                media {
                    gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        breakpoints: [360, 544, 640, 1024, 1536]
                    )
                }
                color {
                    name
                }
                link {
                    ...ContentCtaData
                }
            }
            ... on ContentfulContentVideo {
                contentful_id
                youtubeVideoId
                altText
            }
        }
        color {
            name
        }
        reverse
    }
`;
