import { Column, ColXsType, Typography } from "@2po/component-library";
import { ISectionContact } from "@types";
import { graphql } from "gatsby";
import React from "react";
import { ContentfulForm } from "../";
import {
    StyledContentfulImage,
    StyledContentWrapper,
    StyledGrid,
} from "./ContentfulSectionContact.styled";

const ContentfulSectionContact = ({
    content,
    className,
}: {
    content: ISectionContact;
    className?: string;
}) => {
    if (!content) {
        return null;
    }

    const columnProps = {
        xsSpan: "full" as ColXsType,
        lSpan: "half" as ColXsType,
    };

    const { title, form, image } = content;

    return (
        <StyledGrid
            className={className}
            gapColL={400}
            gapColM={0}
            gapColS={0}
            gapColXl={400}
            gapColXs={0}
        >
            <Column alignSelf="center" {...columnProps}>
                <StyledContentWrapper>
                    <Typography tag="h1" type="h3">
                        {title}
                    </Typography>
                    {form && <ContentfulForm content={form} />}
                </StyledContentWrapper>
            </Column>
            <Column alignSelf="center" {...columnProps}>
                <StyledContentfulImage content={image} />
            </Column>
        </StyledGrid>
    );
};

export default ContentfulSectionContact;

export const query = graphql`
    fragment SectionContactData on ContentfulSectionContact {
        title
        form {
            ...FormData
        }
        image {
            ...GeneralImageData
            media {
                gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    breakpoints: [360, 544, 640, 1024, 1536]
                )
            }
        }
    }
`;
