import { Colors, FontTypes } from "@2po/component-library";
import { handleTextColorByBackground } from "@utils";
import React from "react";
import {
    StyledBackground,
    StyledContentfulHeader,
    StyledLogoPattern,
    StyledLogoPatternWrapper,
} from "./Hero.styled";

export interface HeroInterface {
    className?: string;
    eyebrow?: string[];
    title: string;
    highlightColor?: Colors;
    color?: Colors;
    headerType?: FontTypes;
}

const Hero = ({
    eyebrow,
    title,
    highlightColor,
    color,
    className,
    headerType,
}: HeroInterface) => (
    <StyledBackground className={className} color={color}>
        <StyledContentfulHeader
            eyebrow={eyebrow}
            eyebrowColor={handleTextColorByBackground(color)}
            headerType={headerType}
            linkColor={highlightColor}
            linkColorHover={highlightColor}
            textColor={handleTextColorByBackground(color)}
            title={title}
        />
        <StyledLogoPatternWrapper>
            <StyledLogoPattern repeatX={3} repeatY={3} />
        </StyledLogoPatternWrapper>
    </StyledBackground>
);

export default Hero;
