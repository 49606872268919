import { IContentCta } from "@types";
import { trimSlashes } from "./text";

export const mapNavigationLinks = (
    links: IContentCta[],
    findActive?: boolean,
    onClick?: any
) =>
    links
        ?.map((navLink) => {
            const getHref = () => {
                const { internalLink, externalLink } = navLink;

                if (internalLink?.slug) {
                    return internalLink?.slug;
                }

                return externalLink;
            };

            const href = getHref();

            return {
                label: navLink.label,
                href: href === "/" ? "" : href,
                onClick,
                highlight:
                    findActive &&
                    typeof window !== "undefined" &&
                    trimSlashes(window?.location?.pathname) === href,
            };
        })
        ?.filter((v) => v);
