import { Column, Figure, Flex, Grid } from "@2po/component-library";
import { ContentfulRichText, DefaultBackground } from "@components";
import { IContentHighlightedValue, IContentRichText } from "@types";
import { graphql } from "gatsby";
import React from "react";

interface SolutionInfoSectionInterface {
    specs?: IContentHighlightedValue[];
    details?: IContentRichText;
}

const SolutionInfoSection = ({
    specs,
    details,
}: SolutionInfoSectionInterface) => (
    <DefaultBackground>
        <Grid gapColM={500} gapColS={500} gapColXs={500}>
            <Column lSpan={2} xlSpan={3} xsSpan="full">
                {!!specs?.length && (
                    <Flex flexDirection="column" gap={300}>
                        {specs.map((spec, index) => {
                            const { caption, value } = spec;

                            if (!caption || !value) return null;

                            return (
                                <Figure
                                    copy={caption}
                                    key={`solution-spec-${caption}-${value}-${index}`}
                                    title={value}
                                />
                            );
                        })}
                    </Flex>
                )}
            </Column>
            <Column lSpan={6} xlSpan={9} xsSpan="full">
                <Flex flexDirection="column" gap={300}>
                    <ContentfulRichText
                        content={details}
                        icon="check"
                        iconColor="primary"
                    />
                </Flex>
            </Column>
        </Grid>
    </DefaultBackground>
);

export default SolutionInfoSection;

export const query = graphql`
    fragment SolutionInfoSectionData on ContentfulSolution {
        displayName
        category
        specs {
            value
            caption
        }
        details {
            ...ContentRichTextData
        }
        color {
            name
        }
    }
`;
