import { Flex, Typography } from "@2po/component-library";
import { ContentfulRichText } from "@components";
import { IContentRichText, ISectionColumns, ITab } from "@types";
import { chunkArray } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import {
    RowContentWrapper,
    StyledCardTextual,
    StyledDefaultBackground,
    StyledDivider,
} from "./ContentfulSectionColumns.styled";

interface ContentfulSectionColumnsProps {
    content: ISectionColumns;
    className?: string;
}

const ContentfulSectionColumns = ({
    content,
    className,
}: ContentfulSectionColumnsProps) => {
    const { color, title, columns } = content;

    const splitByRow: [(IContentRichText | ITab)[]] = chunkArray(
        columns as any,
        4
    );
    const amountOfRows = splitByRow?.length;

    const evenOutColumns = () => {
        const arrayCopy = splitByRow.concat([]);

        const lastRow = arrayCopy[amountOfRows - 1];

        if (amountOfRows === 1 || lastRow.length !== 1) return arrayCopy;

        const secondLastRow = arrayCopy[amountOfRows - 2];

        const newSecondLastRow = secondLastRow.slice(0, -1);
        const deletedItem = secondLastRow.splice(-1);
        const newLastRow = deletedItem.concat(lastRow);

        return [
            ...arrayCopy.slice(0, amountOfRows - 2),
            newSecondLastRow,
            newLastRow,
        ];
    };

    const enevedColumns = evenOutColumns();

    return (
        <>
            <StyledDefaultBackground className={className} color={color?.name}>
                <Typography fontWeight="bold" type="h4">
                    {title}
                </Typography>
                {enevedColumns.map((row, rowIndex) => {
                    const hasNextRow = rowIndex + 1 < amountOfRows;

                    return (
                        <Flex
                            flexDirection="column"
                            key={`${title}-row-${rowIndex}`}
                        >
                            <RowContentWrapper>
                                {row.map((item, index) => {
                                    const richTextContent =
                                        (item as ITab)?.content || item;

                                    return (
                                        <StyledCardTextual
                                            copy={
                                                <ContentfulRichText
                                                    content={richTextContent}
                                                />
                                            }
                                            key={`${title}-section-column-${index}-${item.id}`}
                                            title={(item as ITab).title}
                                        />
                                    );
                                })}
                            </RowContentWrapper>
                            {hasNextRow && <StyledDivider width="screen" />}
                        </Flex>
                    );
                })}
            </StyledDefaultBackground>
        </>
    );
};

export default ContentfulSectionColumns;

export const query = graphql`
    fragment SectionColumnsData on ContentfulSectionColumns {
        title
        columns {
            ... on Node {
                ...GeneralModelData
                ...ContentRichTextData
                ...TabData
            }
        }
        color {
            name
        }
    }
`;
