import { color, fontFamily, spacer, zIndex } from "@2po/component-library";
import { Navigation } from "@components";
import styled from "styled-components";

export const StyledLayoutDefault = styled.div`
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: ${fontFamily("primary")};
    background-color: ${color("white")};
    min-height: 100vh;
    padding-top: ${spacer(350)};
`;

export const StyledNavigation = styled(Navigation)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex("header")};
`;
