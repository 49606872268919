import {
    breakpointDown,
    color,
    Colors,
    Grid,
    spacer,
} from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledGrid = styled(Grid)`
    direction: ${({ reverse }) => (reverse ? "rtl" : "ltr")};

    > * {
        direction: ltr;
    }
`;

interface ImageWrapperProps {
    background?: Colors;
    reverse?: boolean;
}

const mobileSpacing = spacer(300);

export const ImageWrapper = styled.div<ImageWrapperProps>`
    background: ${({ background }) => background && color(background)};

    ${breakpointDown(
        "m",
        css`
            padding-top: ${mobileSpacing};
        `
    )}

    ${({ reverse }) => {
        if (reverse) {
            return css`
                padding-left: ${mobileSpacing};
            `;
        }

        return css`
            padding-right: ${mobileSpacing};
        `;
    }}
`;
