import { useState } from "react";
import useClient from "./useClient";
import useInterval from "./useInterval";
import isEqual from "react-fast-compare";

interface PreviewInterface {
    entryId: string;
    environment?: string;
    host?: string;
    interval?: number;
    query?: Record<string, unknown>;
    space?: string;
    token: string;
}

const usePreview = ({
    entryId,
    environment,
    host,
    interval = 5000,
    query = {},
    space,
    token,
}: PreviewInterface) => {
    const { getEntries, getEntry } = useClient({
        space,
        environment,
        host,
        accessToken: token,
    });

    const [data, setData] = useState();

    const fetch = async () => {
        if (!(entryId || query)) {
            return null;
        }

        if (entryId) {
            const newData = await getEntry(entryId, query);

            if (!isEqual(newData, data)) setData(newData);
        } else {
            const newData = await getEntries({ ...query });
            if (!isEqual(newData, data)) setData(data);
        }
    };

    useInterval(() => {
        fetch();
    }, interval);

    return { data };
};

export default usePreview;
