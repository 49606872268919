import {
    Colors,
    Divider,
    FontTypes,
    Icons,
    List,
    TextAligns,
} from "@2po/component-library";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, Inline } from "@contentful/rich-text-types";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
    renderRichText,
} from "gatsby-source-contentful/rich-text";
import React, { Fragment } from "react";
import { Mapper } from "../Contentful/ContentfulModelMapper/ContentfulModelMapper";
import { StyledLink, StyledParagraph } from "./RichText.styled";

interface MapperProps {
    copy?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    customRenderNode?: any;
    className?: string;
    color?: Colors;
    textAlign?: TextAligns;
    icon?: Icons;
    iconColor?: Colors;
}

interface NodeBlock extends Paragraph {
    value: string;
}

const processNode = (
    node: any,
    defaultProps?: {
        className?: string;
        color?: Colors;
        textAlign?: TextAligns;
    },
    type: FontTypes = "largeBody"
) => {
    const content = node?.content?.map((content: any, index: number) => {
        const key = `node-item-${content?.value}-${index}`;

        if (content?.nodeType === "hyperlink") {
            return <Fragment key={key}>{renderLink(content, type)}</Fragment>;
        }
        if (content.nodeType === "entry-hyperlink") {
            return (
                <b>
                    <StyledLink href={`/${content.data.target.slug}`} key={key}>
                        {content.content[0].value}
                    </StyledLink>
                </b>
            );
        }

        return (
            <Fragment key={key}>
                {documentToReactComponents(content, {
                    renderText: (text) => {
                        const str = text
                            .split("\n")
                            .reduce(
                                (children, textSegment, index) => [
                                    ...children,
                                    index > 0 && <br key={index} />,
                                    textSegment,
                                ],
                                []
                            );
                        return str;
                    },
                })}
            </Fragment>
        );
    });

    return (
        <StyledParagraph
            className={defaultProps?.className}
            color={defaultProps?.color}
            textAlign={defaultProps?.textAlign}
            type={type}
        >
            {content}
        </StyledParagraph>
    );
};

const processEmbeddedEntry = (node: any) => {
    if (!node?.data?.target) return null;
    return (
        <Mapper
            additionalProps={{ type: "button" }}
            content={node?.data?.target}
        />
    );
};

const renderLink = ({ data, content }: any, type: FontTypes = "largeBody") => (
    <StyledLink href={data?.uri} type={type}>
        {content?.[0]?.value}
    </StyledLink>
);

const renderList = (
    _: any,
    children: any,
    icon?: Icons,
    iconColor?: Colors
) => <List icon={icon} iconColor={iconColor} items={children} />;

const processNodes = ({
    className,
    color,
    textAlign,
    icon,
    iconColor,
}: Partial<MapperProps>) => {
    const defaultProps = { className, color, textAlign };

    return {
        [BLOCKS.PARAGRAPH]: (node: NodeBlock) =>
            processNode(node, defaultProps),
        [BLOCKS.HEADING_1]: (node: NodeBlock) =>
            processNode(node, defaultProps, "h1"),
        [BLOCKS.HEADING_2]: (node: NodeBlock) =>
            processNode(node, defaultProps, "h2"),
        [BLOCKS.HEADING_3]: (node: NodeBlock) =>
            processNode(node, defaultProps, "h3"),
        [BLOCKS.HEADING_4]: (node: NodeBlock) =>
            processNode(node, defaultProps, "h4"),
        [BLOCKS.EMBEDDED_ENTRY]: processEmbeddedEntry,
        [BLOCKS.HR]: () => <Divider />,
        [INLINES.HYPERLINK]: renderLink,
        [INLINES.ENTRY_HYPERLINK]: (node: Inline) => (
            <StyledLink href={`/${node.data.target.slug}`}>
                {node.content[0].value}
            </StyledLink>
        ),
        [BLOCKS.UL_LIST]: (node: NodeBlock, children: any) =>
            renderList(node, children, icon, iconColor),
    };
};

const RichText = ({
    copy,
    customRenderNode = {},
    className,
    color,
    textAlign,
    icon,
    iconColor,
}: MapperProps) => {
    if (!copy?.raw) {
        return null;
    }

    const options: Options = {
        renderNode: {
            ...processNodes({ className, color, textAlign, icon, iconColor }),
            ...customRenderNode,
        },
        renderText: (text) => {
            const str = text
                .split("\n")
                .reduce(
                    (children, textSegment, index) => [
                        ...children,
                        index > 0 && <br key={index} />,
                        textSegment,
                    ],
                    []
                );
            return str;
        },
    };
    return <Fragment>{renderRichText(copy, options)}</Fragment>;
};

export default RichText;
