import { PageHead } from "@components";
import { IMetadata } from "@types";
import { graphql } from "gatsby";
import React, { ReactNode } from "react";

type MetadataInterface = IMetadata & {
    slug: string;
    canonicalUrl: string;
};

interface ContentfulMetadataInterface {
    content?: MetadataInterface;
    children: ReactNode;
}

const ContentfulMetadata = ({
    content,
    children,
}: ContentfulMetadataInterface) => {
    if (!content) return <>{children}</>;

    const {
        noIndexNoFollow,
        metaTitle,
        metaDescription,
        metaImage,
        slug,
        canonicalUrl,
    } = content;

    return (
        <PageHead
            canonicalUrl={canonicalUrl}
            description={metaDescription?.metaDescription}
            image={metaImage}
            noIndexNoFollow={noIndexNoFollow}
            pathname={slug}
            title={metaTitle}
        >
            {children}
        </PageHead>
    );
};

export default ContentfulMetadata;

export const query = graphql`
    fragment MetaData on ContentfulMetadata {
        id
        __typename
        name
        keywords
        metaDescription {
            metaDescription
        }
        metaImage {
            media {
                file {
                    url
                    fileName
                    contentType
                }
            }
        }
        metaTitle
        noIndexNoFollow
    }
`;
