import { ColXsType, Grid } from "@2po/component-library";
import { DefaultBackground } from "@components";
import { ISectionDuplex } from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import { Mapper } from "../ContentfulModelMapper/ContentfulModelMapper";
import { StyledColumn } from "./ContentfulSectionDuplex.styled";

interface ContentfulSectionDuplexProps {
    content: ISectionDuplex;
    className?: string;
}

const ContentfulSectionDuplex = ({
    content,
    className,
}: ContentfulSectionDuplexProps) => {
    if (!(content?.sectionA && content?.sectionB)) {
        return null;
    }

    const { color, sectionA, sectionB, focus } = content;

    const defaultColumnProps = {
        xsSpan: "full" as ColXsType,
    };

    const getFocusSpan = (section: "A" | "B") => {
        if (!focus) return "half";

        if (section === focus) return 2;

        return 1;
    };

    const columnAProps = {
        ...defaultColumnProps,
        mSpan: getFocusSpan("A"),
    };
    const columnBProps = {
        ...defaultColumnProps,
        mSpan: getFocusSpan("B"),
    };

    return (
        <DefaultBackground className={className} color={color?.name}>
            <Grid l={3} m={3} xl={3}>
                <StyledColumn alignSelf="center" {...columnAProps}>
                    <Mapper
                        additionalProps={{
                            textColor: handleTextColorByBackground(color?.name),
                        }}
                        content={sectionA}
                    />
                </StyledColumn>
                <StyledColumn alignSelf="center" {...columnBProps}>
                    <Mapper
                        additionalProps={{
                            textColor: handleTextColorByBackground(color?.name),
                        }}
                        content={sectionB}
                    />
                </StyledColumn>
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionDuplex;

export const query = graphql`
    fragment SectionDuplexData on ContentfulSectionDuplex {
        sectionA {
            ...GeneralModelData
            ...ContentLogoPatternData
            ...ContentCtaData
            ...ContentRichTextData
            ...ContentVideoData
            ... on ContentfulContentImage {
                ...GeneralImageData
                media {
                    gatsbyImageData(width: 500, placeholder: BLURRED)
                }
                link {
                    ...ContentCtaData
                }
            }
        }
        sectionB {
            ...GeneralModelData
            ...ContentLogoPatternData
            ...ContentCtaData
            ...ContentRichTextData
            ...ContentVideoData
            ... on ContentfulContentImage {
                ...GeneralImageData
                media {
                    gatsbyImageData(width: 500, placeholder: BLURRED)
                }
                link {
                    ...ContentCtaData
                }
            }
        }
        color {
            name
        }
        focus
    }
`;
