import { CarouselChain, CarouselChainItem } from "@2po/component-library";
import "@styles/swiper.css";
import {
    CarouselItemType,
    IContentImage,
    ISectionCarousel,
    ISolution,
} from "@types";
import React from "react";
import ContentfulImage from "../ContentfulImage/ContentfulImage";
import {
    ChainItemWrapper,
    ImageWrapper,
    StyledBackground,
} from "./ContentfulSectionCarouselChain.styled";

interface ContentfulSectionCarouselChainProps {
    className?: string;
    content: ISectionCarousel;
}

const itemHeight = 130;
const itemWidth = 130;

const CarouselBodyMapper = ({ item }: { item: CarouselItemType }) => {
    if (item.__typename === "ContentfulSolution") {
        const typedItem = item as ISolution;
        const { image, category, color } = typedItem;

        return (
            <CarouselChainItem
                alt={`${image?.media?.description || ""} ${
                    category || ""
                } image`}
                backgroundColor={color?.name}
                image={() =>
                    image && (
                        <ImageWrapper width={itemWidth}>
                            <ContentfulImage content={image} loading="lazy" />
                        </ImageWrapper>
                    )
                }
            />
        );
    }

    if (item.__typename === "ContentfulContentImage") {
        const image = item as IContentImage;

        return (
            <CarouselChainItem
                alt={`${image?.caption || ""} image`}
                backgroundColor={image?.color as any}
                caption={image?.caption}
                image={() =>
                    image && (
                        <ImageWrapper width={itemWidth}>
                            <ContentfulImage content={image} />
                        </ImageWrapper>
                    )
                }
            />
        );
    }

    return null;
};

const ContentfulSectionCarouselChain = ({
    content,
    className,
}: ContentfulSectionCarouselChainProps) => {
    if (!content?.items?.length) {
        return null;
    }

    const { color, items } = content;

    return (
        <StyledBackground className={className} color={color?.name}>
            <CarouselChain>
                {items.map((item) => (
                    <ChainItemWrapper height={itemHeight} key={item.id}>
                        <CarouselBodyMapper item={item} />
                    </ChainItemWrapper>
                ))}
            </CarouselChain>
        </StyledBackground>
    );
};

export default ContentfulSectionCarouselChain;
