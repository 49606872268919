import { Column } from "@2po/component-library";
import { ISectionQuote } from "@types";
import { handleTextColorByBackground } from "@utils";
import { graphql } from "gatsby";
import React from "react";
import {
    StyledAuthorInfo,
    StyledBackground,
    StyledGrid,
    StyledQuote,
} from "./ContentfulSectionQuote.styled";

interface ContentfulSectionQuoteProps {
    content: ISectionQuote;
    className?: string;
}

const ContentfulSectionQuote = ({
    content,
    className,
}: ContentfulSectionQuoteProps) => {
    if (!content?.quote?.quoteText) {
        return null;
    }

    const {
        color,
        quote: { quoteText, author },
    } = content;

    return (
        <StyledBackground className={className} color={color?.name}>
            <StyledGrid gapColS={200} gapColXs={200}>
                <Column
                    lSpan={6}
                    lStart={1}
                    xlSpan={9}
                    xsSpan="full"
                    xsStart={2}
                >
                    <StyledQuote
                        iconColor={handleTextColorByBackground(color?.name)}
                    >
                        {quoteText.quoteText}
                    </StyledQuote>
                </Column>

                {author && (
                    <Column
                        lSpan={2}
                        lStart={7}
                        xlSpan={2}
                        xlStart={11}
                        xsSpan="full"
                        xsStart={2}
                    >
                        <StyledAuthorInfo
                            author={`${author.firstName} ${author.lastName}`}
                            client={author.client?.name}
                            functionTitle={author.function}
                        />
                    </Column>
                )}
            </StyledGrid>
        </StyledBackground>
    );
};

export default ContentfulSectionQuote;

export const query = graphql`
    fragment SectionQuoteData on ContentfulSectionQuote {
        quote {
            ...QuoteData
        }
        color {
            name
        }
    }
    fragment QuoteData on ContentfulContentQuote {
        quoteText {
            quoteText
        }
        author {
            firstName
            lastName
            function
            client {
                name
                websiteLink
            }
        }
    }
`;
