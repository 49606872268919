import { breakpointDown, CardLogo, spacer } from "@2po/component-library";
import styled, { css } from "styled-components";

export const StyledCardLogo = styled(CardLogo)`
    ${breakpointDown(
        "m",
        css`
            padding: ${spacer(150)};
        `
    )}
`;
