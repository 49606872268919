import { Column, Flex, Grid } from "@2po/component-library";
import { CaseInfo, ContentfulRichText, DefaultBackground } from "@components";
import { CaseSpecTypes, IClient, IContentRichText } from "@types";
import { getSpecValues } from "@utils";
import { graphql } from "gatsby";
import React from "react";

interface CaseInfoSectionInterface {
    specs: CaseSpecTypes[];
    details: IContentRichText;
    client?: IClient;
}

const CaseInfoSection = ({
    specs,
    details,
    client,
}: CaseInfoSectionInterface) => {
    const serviceNames = getSpecValues(
        specs,
        "ContentfulService",
        "displayName"
    );
    const solutionNames = getSpecValues(
        specs,
        "ContentfulSolution",
        "displayName"
    );
    const technologyNames = getSpecValues(
        specs,
        "ContentfulTechnology",
        "displayName"
    );

    return (
        <DefaultBackground>
            <Grid gapColM={500} gapColS={500} gapColXs={500}>
                <Column lSpan={2} xlSpan={3} xsSpan="full">
                    <CaseInfo
                        client={client}
                        services={serviceNames}
                        solutions={solutionNames}
                        technologies={technologyNames}
                    />
                </Column>
                <Column lSpan={6} xlSpan={9} xsSpan="full">
                    <Flex flexDirection="column" gap={300}>
                        <ContentfulRichText
                            content={details}
                            icon="check"
                            iconColor="primary"
                        />
                    </Flex>
                </Column>
            </Grid>
        </DefaultBackground>
    );
};

export default CaseInfoSection;

export const query = graphql`
    fragment CaseInfoSectionData on ContentfulCase {
        client {
            name
            websiteLink
        }
        title
        caseDetails {
            ...ContentRichTextData
        }
        background {
            ...GeneralImageData
            media {
                gatsbyImageData(
                    layout: FIXED
                    width: 50
                    placeholder: DOMINANT_COLOR
                )
            }
        }
        caseSpecs {
            ...GeneralModelData
            ... on ContentfulService {
                displayName
            }
            ... on ContentfulSolution {
                displayName
                description {
                    description
                }
            }
            ... on ContentfulTechnology {
                displayName
            }
        }
    }
`;
