import { Column, Grid } from "@2po/component-library";
import { ISectionHighlight } from "@types";
import { graphql } from "gatsby";
import React from "react";
import {
    StyledBackground,
    StyledContentfulImage,
    StyledHeader,
    StyledLogoPattern,
} from "./ContentfulSectionHighlight.styled";

const ContentfulSectionHighlight = ({
    content: { highlight, color, ctaCopy, label },
    className,
}: {
    content: ISectionHighlight;
    className?: string;
}) => {
    if (!highlight) {
        return null;
    }

    const { title, image, page, background } = highlight;

    return (
        <StyledBackground className={className} color={color?.name}>
            <StyledLogoPattern
                background={background?.media?.file?.url}
                color="primary"
                repeatX={4}
                repeatY={2}
            />
            <Grid>
                {image && (
                    <Column lSpan={6} xlSpan={8} xsSpan={4}>
                        <StyledContentfulImage content={image} />
                    </Column>
                )}
                <Column alignSelf="end" lSpan={2} xlSpan={4} xsSpan={4}>
                    <StyledHeader
                        eyebrow={label}
                        headerType="h4"
                        linkHref={page?.[0]?.slug}
                        linkLabel={ctaCopy}
                        title={title}
                    />
                </Column>
            </Grid>
        </StyledBackground>
    );
};

export default ContentfulSectionHighlight;

export const query = graphql`
    fragment SectionHighlightData on ContentfulSectionHighlight {
        color {
            name
        }
        label
        ctaCopy
        highlight {
            title
            image {
                ...GeneralImageData
                media {
                    gatsbyImageData(
                        width: 1000
                        placeholder: BLURRED
                        breakpoints: [360, 544, 640, 1024, 1536]
                    )
                    description
                }
                link {
                    ...ContentCtaData
                }
            }
            background {
                ...GeneralImageData
                media {
                    gatsbyImageData(
                        layout: FIXED
                        width: 50
                        placeholder: DOMINANT_COLOR
                    )
                }
            }
            page {
                slug
            }
        }
        color {
            name
        }
    }
`;
