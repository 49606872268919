import * as Yup from "yup";

const CONTAINS_ONLY_CHARS_REGEX = /^\s*\S[\s\S]*$/;
const PHONE_REGEX = /^[0-9,+() ]{9,20}$/;
const INPUT_CHAR_MIN = 2;
const INPUT_CHAR_MAX = 40;
const MESSAGE_CHAR_LIMIT = 2000;

interface BaseValidationOptions {
    min?: number;
    max?: number;
    required?: boolean;
    regex?: RegExp;
    message?: string;
}

const useFormValidation = () => {
    const stringValidationBase = Yup.string();

    const errorMSG = {
        NAME: "Valid name required",
        FIRST_NAME: "Valid name required",
        SUBJECT: "Valid subject required",
        EMAIL: "Valid email required",
        MESSAGE: "Valid message required",
        REQUIRED: "This field is required",
        PHONE: "Valid phone number required",
    };

    const optionalCheck = (
        validation: any,
        optional?: boolean,
        message = errorMSG.REQUIRED
    ) => (optional ? validation : validation.required(message));

    const minLengthMessage = (obj: any) =>
        `At least ${obj.min.toString()} characters required`;

    const maxLengthMessage = (obj: any) =>
        `At most ${obj.min.toString()} characters required`;

    const validationFunction = (
        min?: number,
        max?: number,
        regex?: RegExp,
        message: string = errorMSG.MESSAGE
    ) => {
        const baseValidation = regex
            ? stringValidationBase.matches(regex, message)
            : stringValidationBase;

        if (min && max) {
            return baseValidation
                .min(min, minLengthMessage)
                .max(max, maxLengthMessage);
        }

        if (min) {
            return baseValidation.min(min, minLengthMessage);
        }

        if (max) {
            return baseValidation.max(max, maxLengthMessage);
        }

        return baseValidation;
    };

    const booleanValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) => {
        if (optional) {
            return Yup.boolean();
        }

        return Yup.boolean().oneOf(
            [true],
            options.message || errorMSG.REQUIRED
        );
    };

    const emailValidation = (optional?: boolean) => {
        const validation = validationFunction(
            undefined,
            INPUT_CHAR_MAX,
            undefined,
            errorMSG.EMAIL
        ).email(errorMSG.EMAIL);

        return optionalCheck(validation, optional);
    };

    const stringOnlyValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) => {
        const validation = validationFunction(
            options.min,
            options.max,
            options.regex || CONTAINS_ONLY_CHARS_REGEX,
            options.message
        );

        return optionalCheck(validation, optional);
    };

    const nameValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) => {
        const validation = validationFunction(
            options.min || INPUT_CHAR_MIN,
            options.max || INPUT_CHAR_MAX,
            options.regex || CONTAINS_ONLY_CHARS_REGEX,
            options.message || errorMSG.NAME
        );

        return optionalCheck(validation, optional);
    };

    const firstNameValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) =>
        nameValidation(
            { ...options, message: options.message || errorMSG.FIRST_NAME },
            optional
        );

    const subjectValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) =>
        nameValidation(
            { ...options, message: options.message || errorMSG.SUBJECT },
            optional
        );

    const messageValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean
    ) =>
        nameValidation(
            {
                ...options,
                max: options.max || MESSAGE_CHAR_LIMIT,
                message: options.message || errorMSG.MESSAGE,
            },
            optional
        );

    const phoneValidation = (optional?: boolean) => {
        const validation = validationFunction(
            undefined,
            undefined,
            PHONE_REGEX,
            errorMSG.PHONE
        );

        return optionalCheck(validation, optional);
    };

    const getValidationSchema = (schemaObject: any) =>
        Yup.object().shape(schemaObject);

    return {
        emailValidation,
        stringOnlyValidation,
        getValidationSchema,
        booleanValidation,
        nameValidation,
        firstNameValidation,
        subjectValidation,
        messageValidation,
        phoneValidation,
    };
};

export default useFormValidation;
