import { Typography } from "@2po/component-library";
import { IClient } from "@types";
import React, { Fragment } from "react";
import {
    ContentWrapper,
    InfoWrapper,
    StyledLink,
    StyledTopic,
} from "./CaseInfo.styled";

interface CaseInfoInterface {
    client?: IClient;
    services: string[];
    solutions: string[];
    technologies: string[];
}

const CaseInfo = ({
    client,
    services,
    solutions,
    technologies,
}: CaseInfoInterface) => {
    const contentMapper = [
        { key: "SERVICES", values: services },
        { key: "SOLUTIONS", values: solutions },
        { key: "TECHNOLOGIES", values: technologies },
    ];

    return (
        <ContentWrapper>
            {client && (
                <InfoWrapper>
                    <StyledTopic type="label">Client</StyledTopic>
                    <Typography>{client.name}</Typography>
                </InfoWrapper>
            )}
            {contentMapper.map(({ key, values }, index) => (
                <InfoWrapper key={index}>
                    <StyledTopic type="label">{key}</StyledTopic>
                    <Typography key={key}>
                        {values?.map((value, key) => (
                            <Fragment key={key}>
                                {value}
                                {key !== values?.length - 1 && " | "}
                            </Fragment>
                        ))}
                    </Typography>
                </InfoWrapper>
            ))}
            {client?.websiteLink && (
                <StyledLink href={client.websiteLink} padding={0} type="body">
                    Visit website
                </StyledLink>
            )}
        </ContentWrapper>
    );
};

export default CaseInfo;
