import { Colors, Column, Grid } from "@2po/component-library";
import { DefaultBackground } from "@components";
import { IContentImage, ISectionGrid } from "@types";
import { graphql } from "gatsby";
import React from "react";
import ContentfulImage, {
    ContentfulImageInterface,
} from "../ContentfulImage/ContentfulImage";
import { StyledCardLogo } from "./ContentfulSectionGrid.styled";

export interface CardLogoInterface {
    image: ContentfulImageInterface;
    color: {
        name: Colors;
    };
    id: string;
}

interface ContentfulSectionGridProps {
    content: ISectionGrid;
    showCaption?: boolean;
    className?: string;
}

const ContentfulSectionGrid = ({
    content,
    className,
}: ContentfulSectionGridProps) => {
    if (!content?.items?.length) {
        return null;
    }

    const { color, items } = content;

    return (
        <DefaultBackground className={className} color={color?.name}>
            <Grid>
                {items?.map((item) => {
                    const getBody = () => {
                        if (item.__typename === "ContentfulContentImage") {
                            const typedItem = item as IContentImage;
                            return (
                                <StyledCardLogo
                                    backgroundColor={typedItem?.color?.name}
                                    image={() => (
                                        <ContentfulImage content={typedItem} />
                                    )}
                                />
                            );
                        }
                    };

                    return (
                        <Column key={item.id} mSpan={2} xlSpan={3} xsSpan={2}>
                            {getBody()}
                        </Column>
                    );
                })}
            </Grid>
        </DefaultBackground>
    );
};

export default ContentfulSectionGrid;

export const query = graphql`
    fragment SectionGridData on ContentfulSectionGrid {
        items {
            ...GeneralModelData
            ...SectionGridCardImage
        }
        color {
            name
        }
    }

    fragment SectionGridCardImage on ContentfulContentImage {
        media {
            gatsbyImageData(width: 350, placeholder: BLURRED)
            description
        }
        link {
            ...ContentCtaData
        }
        color {
            name
        }
    }
`;
